/* global dataLayer */
import extender from '@vanillaUtils/Extender'
import DomHelper from '../DomHelperExtend'
import Listeners from '../ListenersExtend'
import AnalyticsHub from '../../utils/AnalyticsHub'

class AnalyticPromotion extends extender(DomHelper, Listeners) {
  static getDomClasses () {
    return {
    }
  }

  get itemData () {
    return this.data
  }

  setRemainingInfo () {
    AnalyticsHub.addPosition(this.domRoot)
    this.data = AnalyticsHub.buildEventData(this.domRoot)
    this.viewSent = false
  }

  init () {
    this._selectors = {
      c2as: 'a'
    }
    this.setRemainingInfo()

    this.domRoot.querySelectorAll(this.selectors.c2as).forEach(c2a => {
      c2a.addEventListener('click', event => {
        this.sendCallToActionEvent(event)
      })
    })
  }
  clearDatalayer () {
    AnalyticsHub.push({ ecommerce: null }, false)
  }

  sendCallToActionEvent (event) {
    /* eslint-disable camelcase */
    this.clearDatalayer()
    AnalyticsHub.push({
      event: 'select_promotion',
      ecommerce: {
        ...this.data,
        c2a_id: event.target.dataset.id,
        c2a_name: event.target.dataset.gtmC2a
      }
    }, false)
    /* eslint-enable */
  }
}

export default AnalyticPromotion

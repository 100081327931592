<template>
  <input
    :id="id"
    ref="input"
    :value="modelValue"
    :name="id"
    :type="type"
    :disabled="disabled"
    :placeholder="placeholder"
    :readonly="readonly"
    v-bind="$props"
    :data-gtm-input="id"
    :data-gtm-input-name="slugLabel"
    class="cc-textInput"
    @input="onChange" >
</template>

<script>
import FormField from './mixins/FormField'

export default {
  name: 'CCInputField',

  mixins: [FormField],

  props: {
    modelValue: {
      type: [String, null],
      required: true,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: ''
    },
    disabled: Boolean,
    readonly: Boolean
  },

  methods: {
    onChange (event) {
      this.$emit('update:modelValue', event.target.value)
    },
    focusInput () {
      document.getElementById(this.id).focus()
    },
    focus () {
      this.$refs?.input?.focus()
    }
  }
}
</script>

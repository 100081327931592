
import SlideshowManager from './SlideshowManager'

function init () {
  window.ManagerNotifier.register(SlideshowManager)
}

export {
  init
}

<template>
  <textarea
    :id="id"
    :value="modelValue"
    :name="id"
    :disabled="disabled"
    :placeholder="placeholder"
    :readonly="readonly"
    class="cc-textareaInput"
    :data-gtm-input="id"
    :data-gtm-input-name="slugLabel"
    rows="5"
    cols="33"
    @input="onChange" />
</template>

<script>
import FormField from './mixins/FormField'

export default {
  name: 'CCTextareaField',

  mixins: [FormField],

  props: {
    modelValue: {
      type: [String, null],
      required: true,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: Boolean,
    readonly: Boolean
  },

  methods: {
    onChange (event) {
      this.$emit('update:modelValue', event.target.value)
    }
  }
}
</script>


import mitt from 'mitt'
import { LoadingPlugin } from 'vue-loading-overlay'
import { vMaska } from 'maska'
import vClickOutside from 'click-outside-vue3'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import ManagerNotifier from './utils/ManagerNotifier'
import { init as initDomModules } from '@vanilla/dom_modules'
import initPhoneTrigger from './phone-trigger/_index'
import Toast, { POSITION } from 'vue-toastification'

function initDomHelpers () {
  initDomModules()
  window.ManagerNotifier.ready()
  initPhoneTrigger()
}

export default {
  install: (app, options) => {
    app.use(LoadingPlugin)
    app.use(PerfectScrollbar)
    app.directive('maska', vMaska)
    app.use(vClickOutside)
    app.use(Toast, {
      position: POSITION.BOTTOM_RIGHT
    })
    window.emmiter = mitt()
    app.config.globalProperties.emitter = window.emmiter
    window.ManagerNotifier = new ManagerNotifier()
  },
  ready: initDomHelpers
}

import ParallaxManager from './ParallaxManager'

function registerParallax () {
  window.ManagerNotifier.register(ParallaxManager)
  window.ManagerNotifier.refresh()
}

export default {
  ready: registerParallax
}

import FormErrorHelper from './FormErrorHelper'
import FormErrors from './errors'
import Scroll from '@vanillaUtils/Scroll'
import get from 'lodash/get'

const formDefaultOptions = {
  /**
   * Override method used to save instance.
   * By default, store and update method will be used.
   */
  storeMethodName: 'store',
  updateMethodName: 'update'
}

export default {
  form: formDefaultOptions,

  provide () {
    const _this = this

    return {
      formErrors: _this.formErrors,
      handleError: _this.handleError
    }
  },

  mixins: [FormErrorHelper],

  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      loading: true,
      saving: false,
      formErrors: new FormErrors(),
      savePromise: Promise.resolve()
    }
  },

  methods: {
    handleError (error) {
      if (!error) {
        return
      }

      if (error.response) {
        this.formErrors.set(error.response.data.errors)
        this.$nextTick(() => {
          if (!scrollToFirstError()) {
            // Fallback error reporting when there are errors, but they are not visible.
            RemoteErrors(error)
          }
        })
      } else {
        RemoteErrors(error)
      }
    }
  },

  created () {
  }
}

function scrollToFirstError () {
  const firstError = document.querySelector('.cc-form-error')
  if (!firstError) {
    return false
  }

  // @todo - If in modal, scroll in modal (scroll not supported yet in modal)
  let errorInput = firstError.querySelector('input')

  if (!errorInput) {
    errorInput = firstError.querySelector('textarea')
  }

  if (errorInput) {
    errorInput.focus()
  }

  const offset = get(document.getElementsByClassName('mainHeader'), '0.offsetHeight') || 0

  if (offset !== null) {
    Scroll.to(firstError, 600, {
      easing: 'ease',
      offset: (offset * -3)
    })

    return true
  }

  return false
}

export { scrollToFirstError }

class ManagerNotifier {
  constructor () {
    this.managers = []
    this.initialised = false
  }

  register (manager) {
    const registeringManager = manager.register()
    this.managers.push(registeringManager)
    if (this.initialised) {
      this.refresh()
    }
  }

  refresh () {
    this.managers.forEach(manager => {
      manager.refresh()
    })
  }

  ready () {
    this.managers.forEach(manager => {
      manager.ready()
    })
    this.initialised = true
  }
}

export default ManagerNotifier

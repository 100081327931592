
export default {
  inject: {
    injectedCompact: {
      from: 'compact',
      default: null
    },

    slugLabel: {
      from: 'slugLabel',
      default: null
    }
  },

  props: {
    compact: {
      type: Boolean,
      default: null
    },
    description: {
      type: String,
      default: null
    }
  },

  computed: {
    isCompact () {
      if (this.compact !== null) {
        return this.compact
      }

      if (this.injectedCompact !== null) {
        return this.injectedCompact
      }

      return false
    }
  }
}

import extender from '@vanillaUtils/Extender'
import DomHelper from '@vanilla/dom_modules/DomHelperExtend'
import Listeners from '@vanilla/dom_modules/ListenersExtend'

class Tabs extends extender(DomHelper, Listeners) {
  static getDomClasses () {
    return {
      labels: 'js-tabLabel',
      contents: 'js-tabContent'
    }
  }

  init () {
    this.elements.labels.forEach(tabLabel => {
      tabLabel.addEventListener('click', (event) => {
        event.stopPropagation()
        event.preventDefault()
        this.removeActiveTabClass()
        tabLabel.classList.add('cc-tab-label--active')

        const tabIndex = Array.from(this.elements.labels).indexOf(tabLabel)

        this.elements.contents.forEach(tabContent => {
          tabContent.classList.remove('cc-tab-content--active')
        })

        this.elements.contents[tabIndex].classList.add('cc-tab-content--active')
      })
    })
  }

  removeActiveTabClass () {
    this.domRoot.querySelectorAll('.cc-tab-label--active').forEach(element => {
      element.classList.remove('cc-tab-label--active')
    })
  }
}

export default Tabs

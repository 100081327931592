/* global ready */
import RemoteErrors from '@vanillaUtils/RemoteErrors'
window.RemoteErrors = RemoteErrors

/** Object to query url **/
import param from './utils/param'
window.param = param

/** Query url to Object **/
import deparam from './utils/deparam'
window.deparam = deparam

window.ready = (fn) => {
  if (document.readyState !== 'loading') {
    fn()
    return
  }

  document.addEventListener('DOMContentLoaded', fn)
}

export default {}

const DROPDOWNS_SELECTOR = '.js-dropdown'
const HEADER_SELECTOR = 'header'
const OVERLAY_SELECTOR = '.js-overlay'
const DISPLAY_HEADER_BACKGROUND_CLASS = 'header--open'
const DROPDOWN_OPEN_CLASS = 'megaMenu--visible'
const OVERLAY_OPEN_CLASS = 'overlay--visible'
let isOpen = false

class DropdownManager {
  constructor () {
    this.dropdowns = document.querySelectorAll(DROPDOWNS_SELECTOR)
    this.header = document.querySelector(HEADER_SELECTOR)

    if (this.dropdowns.length) {
      this.init()
    }
  }

  get isHeaderBackgroundDisplayed () {
    return this.header.classList.contains(DISPLAY_HEADER_BACKGROUND_CLASS)
  }

  init () {
    this.dropdowns.forEach((currentDropdown) => {
      currentDropdown.addEventListener('mouseenter', (event) => {
        event.stopPropagation()
        event.preventDefault()

        if (this.isHeaderBackgroundDisplayed) {
          this.removeHeaderBackground()
        }

        this.closeAllDropdowns()

        this.openDropdown(event.target)

        isOpen = true

        this.addListenerToCloseDropdown(event)

        isOpen = false
      })
    })

    this.dropdowns.forEach((currentDropdown) => {

      currentDropdown.addEventListener('click', (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (!isOpen) {
          if (this.isHeaderBackgroundDisplayed) {
            this.removeHeaderBackground();
          }

          this.closeAllDropdowns();
          this.openDropdownParent(event.target);

          isOpen = true

        } else {
          const link = event.target.closest('a');

          if (link) {
            window.location.href = link.getAttribute('href');
          }

          isOpen = false
        }

        this.addListenerToCloseDropdown(event);
      });
    });
  }

  openDropdown (domElement) {
    const parent = this.getCurrentDropdownParent(domElement)
    parent.classList.add(OVERLAY_OPEN_CLASS)
    parent.classList.add(DROPDOWN_OPEN_CLASS)
    this.header.classList.add(DISPLAY_HEADER_BACKGROUND_CLASS)
  }

  openDropdownParent (domElement) {
    const parent = this.getCurrentDropdownParent(domElement)
    parent.parentElement.classList.add(OVERLAY_OPEN_CLASS)
    parent.parentElement.classList.add(DROPDOWN_OPEN_CLASS)
    this.header.classList.add(DISPLAY_HEADER_BACKGROUND_CLASS)

    window.addEventListener('click', (event) => {
      if (!event.target.closest('.megaMenu')) {
        this.closeAllDropdowns()
        isOpen = false
      }
    })
  }

  closeDropdown (domElement) {
    if (domElement.classList.contains(DROPDOWN_OPEN_CLASS)) {
      domElement.classList.remove(DROPDOWN_OPEN_CLASS)
    }

    if (domElement.classList.contains(OVERLAY_OPEN_CLASS)) {
      domElement.classList.remove(OVERLAY_OPEN_CLASS)
    }
  }

  addListenerToCloseDropdown (event) {
    const parent = this.getCurrentDropdownParent(event.target)

    document.querySelector(HEADER_SELECTOR).addEventListener('mouseleave', () => {
      parent.classList.remove(DROPDOWN_OPEN_CLASS)
      this.header.classList.remove(DISPLAY_HEADER_BACKGROUND_CLASS)
      this.abortController = this.getAbortController()
      parent.querySelector(OVERLAY_SELECTOR)?.addEventListener('transitionend', () => {
        parent.classList.remove(OVERLAY_OPEN_CLASS)
      }, { once: true, signal: this.abortController.signal })
    }, { once: true })
  }

  removeHeaderBackground () {
    this.header.classList.remove(DISPLAY_HEADER_BACKGROUND_CLASS)
  }

  closeAllDropdowns () {
    const selector = '.' + DROPDOWN_OPEN_CLASS + ', .' + OVERLAY_OPEN_CLASS
    this.resetAbortController()

    if (document.querySelectorAll(selector).length) {
      document.querySelectorAll(selector).forEach(element => this.closeDropdown(element))
    }
  }

  getCurrentDropdownParent (domElement) {
    return domElement.parentElement
  }

  getAbortController () {
    this.resetAbortController()
    return new AbortController()
  }

  resetAbortController () {
    if (this.abortController) {
      this.abortController.abort()
      delete (this.abortController)
    }
  }
}

export default DropdownManager

import VueScrollTo from 'vue-scrollto'

const { body, documentElement } = document
let { scrollTop } = document.documentElement

let mainHeader

function getMainHeader () {
  if (!mainHeader) {
    mainHeader = document.querySelector('header.mainHeader')
  }

  return mainHeader
}

export default class Scroll {
  static disable () {
    scrollTop = documentElement.scrollTop
    body.style.top = `-${scrollTop}px`
    document.querySelector('body').classList.add('body--disableScroll')
    const html = document.querySelector('html')

    html.style.scrollBehavior = 'initial'
  }

  static enable () {
    if (document.querySelector('body').classList.contains('body--disableScroll')) {
      document.querySelector('body').classList.remove('body--disableScroll')
      documentElement.scrollTop = scrollTop
      body.style.removeProperty('top')
      const html = document.querySelector('html')

      html.style.scrollBehavior = 'smooth'
    }
  }

  static toggle () {
    if (document.querySelector('body').classList.contains('body--disableScroll')) {
      this.enable()
      return
    }
    this.disable()
  }

  static to (domObject, duration = 500, options = {}) {
    options = Object.assign({
      container: 'body',
      easing: 'ease',
      offset: -(getMainHeader()?.offsetHeight) || 0
    }, options)

    VueScrollTo.scrollTo(domObject, duration, options)
  }
}

<template>
  <div :class="{ 'singleExpand--expanded': localExpanded }" class="singleExpand">
    <div
      :class="singleExpandLabelComputedClasses"
      class="singleExpand-label"
      @click="!isLink ? toggle() : null">
      <slot name="label" />
      <button
        v-if="$slots.content"
        class="singleExpand-toggler"
        :class="{ 'singleExpand-toggler--visible': togglerIsVisible }"
        @click="!isLink ? null : toggle()">
        <i class="icon-cms-arrow-down" />
      </button>
    </div>
    <div v-if="localExpanded" class="singleExpand-content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'SingleExpand',

  props: {
    expanded: Boolean,
    mobileExpanded: Boolean,
    mobileBreakpoint: Number,
    isLink: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      localExpanded: this.expanded,
      localExpandedMobile: this.mobileExpanded,
      localBreakpoint: this.mobileBreakpoint,
      togglerIsVisible: true,
      prevWindowWidth: 0
    }
  },

  computed: {
    singleExpandLabelComputedClasses () {
      return {
        'singleExpand-label--opened': this.localExpanded,
        'singleExpand-label--cursor': !this.isLink
      }
    }
  },

  methods: {
    toggle () {
      this.localExpanded = !this.localExpanded
    },
    handleMobile () {
      if (this.prevWindowWidth !== window.innerWidth) {
        this.prevWindowWidth = window.innerWidth
        this.localExpanded = true
        this.togglerIsVisible = false
        const windowSize = window.innerWidth
        if (windowSize < this.localBreakpoint) {
          this.localExpanded = false
          this.togglerIsVisible = true
        }
      }
    }
  },

  mounted () {
    if (this.localExpandedMobile) {
      this.handleMobile()
      window.addEventListener('resize', this.handleMobile)
    }
  },

  beforeDestroy () {
    if (this.localExpandedMobile) {
      window.removeEventListener('resize', this.handleMobile)
    }
  }
}
</script>

<template>
  <div
    v-if="config.key"
    class="securityTurnstile"
    :class="{'securityTurnstile--interactive': isInteractive}">
    <div ref="turnstile" class="securityTurnstile-container" />
  </div>
</template>

<script>
import FormField from '@vanilla/utils/form/FormField'
import LiveScriptLoader from '@vanilla/utils/LiveScriptLoader.js'

export default {
  name: 'SecurityTurnstile',

  emits: ['fallback', 'loaded'],

  mixins: [FormField],

  inject: {
    formData: {
      default: () => ({})
    }
  },

  data () {
    return {
      config: window.Cheetah.formSecurity.config.turnstile,
      turnstileWidgetId: null,
      isInteractive: false
    }
  },

  methods: {
    addButton () {
      if (!this.config.key) {
        this.$emit('fallback')
        return
      }
      this.turnstileWidgetId = window.turnstile.render(this.$refs.turnstile, {
        sitekey: this.config.key,
        theme: 'light',
        language: this.$locale,
        'response-field': false,
        appearance: 'interaction-only',
        callback: this.turnstileCallback,
        'error-callback': this.turnstileErrorCallback,
        'timeout-callback': this.timeoutCallback,
        'expired-callback': this.expiredCallback,
        'before-interactive-callback': this.beforeInteractiveCallback,
        'after-interactive-callback': this.afterInteractiveCallback,
        retry: 'never'
      })
    },
    turnstileCallback (response) {
      this.formData.security.response = response
      this.forgetInputError()
      if (this.formData.security.response) {
        this.$emit('loaded')
      }
    },
    turnstileErrorCallback (response) {
      this.formData.security.response = response
      this.$emit('fallback')
    },
    timeoutCallback () {
      if (this.turnstileWidgetId) {
        window.turnstile.reset(this.turnstileWidgetId)
        this.formErrors.forget('security')
      }
    },
    expiredCallback () {
      if (this.turnstileWidgetId) {
        window.turnstile.reset(this.turnstileWidgetId)
        this.formErrors.forget('security')
      }
    },
    beforeInteractiveCallback () {
      this.isInteractive = true
    },
    afterInteractiveCallback () {
      this.isInteractive = false
    },
    reset () {
      if (this.turnstileWidgetId) {
        window.turnstile.reset(this.turnstileWidgetId)
      }
    }
  },

  mounted () {
    if (!this.config.key) {
      console.log('SecurityTurnstile: missing key to instantiate security')
    }

    LiveScriptLoader.load('https://challenges.cloudflare.com/turnstile/v0/api.js')
      .then(() => {
        this.addButton()
      })

    this.formData['security'] = {
      response: null,
      type: 'turnstile'
    }
  },

  beforeUnmount () {
    if (this.turnstileWidgetId) {
      this.formErrors.forget('security')
      window.turnstile.remove(this.turnstileWidgetId)
      this.turnstileWidgetId = null
    }
  }
}
</script>

<template>
  <teleport to="#app">
    <transition
      :name="transitionName"
      @after-leave="$emit('closed')">
      <div
        v-if="localOpened"
        class="cc-drawer-overlay"
        v-bind="analyticProps"
        :class="overlayClasses"
        @click.self="close">
        <div
          class="cc-drawer"
          :class="context"
          :data-gtm-creative-slot="dataGtmCreativeSlot"
          tabindex="1">
          <div class="cc-drawer-content">
            <div v-if="!hideHeader" class="cc-drawer-header">
              <slot name="header">
                <slot name="title">
                  <div class="cc-drawer-headerTitle">
                    {{ title }}
                  </div>
                </slot>
                <div class="cc-drawer-close" @click="close">
                  <slot name="close">
                    <span class="icon-cms-close" />
                  </slot>
                </div>
              </slot>
            </div>
            <div class="cc-drawer-body">
              <slot />
            </div>
            <div v-if="$slots.footer" class="cc-drawer-footer">
              <slot name="footer" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import Scroll from '@vanillaUtils/Scroll.js'
import hasAnalyticData from '@vanillaUtils/mixins/hasAnalyticData.js'

export default {
  name: 'CCDrawer',

  emits: ['closed'],

  mixins: [hasAnalyticData],

  props: {
    title: {
      type: String,
      default: null
    },
    opened: Boolean,
    drawerClass: {
      type: String,
      default: null
    },
    context: {
      type: String,
      default: ''
    },
    dataGtmCreativeSlot: {
      type: String,
      default: 'drawer'
    },
    position: {
      type: String,
      default: 'right' // right|left
    },
    hideHeader: Boolean
  },

  data () {
    return {
      localOpened: false
    }
  },

  computed: {
    overlayClasses () {
      return {
        'cc-drawer--left': this.position === 'left',
        'cc-drawer--right': this.position !== 'left',
        ...(this.drawerClass ? { [this.drawerClass]: true } : null)
      }
    },
    transitionName () {
      return this.position === 'right' ? 'slideFromRight' : 'slideFromLeft'
    }
  },

  watch: {
    localOpened (value) {
      return value ? Scroll.disable() : Scroll.enable()
    }
  },

  methods: {
    toggle () {
      if (this.localOpened) {
        return this.close()
      }

      return this.open()
    },
    open () {
      this.localOpened = true
    },
    close () {
      this.localOpened = false
    }
  },

  created () {
    if (this.opened) {
      this.open()
    }
  }
}
</script>

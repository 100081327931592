<template>
  <div class="shareButton">
    <a
      :class="classes"
      href="#"
      @click.prevent="toggleShareLinks = !toggleShareLinks">
      <slot />
    </a>
    <div
      v-if="toggleShareLinks"
      v-click-outside="closeLinks"
      class="shareButton-links">
      <a
        class="share-link"
        :href="`https://twitter.com/intent/tweet?url=${finalUrl}&text=${'Share with twitter'}`"
        target="_blank"
        @click="closeLinks">
        <i class="icon-social icon-cms-social-x" />
        <span class="share-linkName">X</span>
      </a>
      <a
        class="share-link"
        :href="`https://www.facebook.com/sharer/sharer.php?u=${finalUrl}`"
        target="_blank"
        @click="closeLinks">
        <i class="icon-social icon-cms-social-facebook" />
        <span class="share-linkName">Facebook</span>
      </a>
      <a
        class="share-link"
        :href="`https://pinterest.com/pin/create/button/?url=${finalUrl}`"
        target="_blank"
        @click="closeLinks">
        <i class="icon-social icon-cms-social-pinterest" />
        <span class="share-linkName">Pinterest</span>
      </a>
      <a
        class="share-link"
        :href="`https://www.linkedin.com/shareArticle?mini=true&url=${finalUrl}`"
        target="_blank"
        @click="closeLinks">
        <i class="icon-social icon-cms-social-linkedin" />
        <span class="share-linkName">LinkedIn</span>
      </a>
      <a
        class="share-link"
        :href="`mailto:?to=&body=${finalUrl}`"
        target="_blank"
        @click="closeLinks">
        <i class="icon-social icon-cms-mail" />
        <span class="share-linkName">Courriel</span>
      </a>
      <a
        class="share-link"
        href="#"
        @click="copyLink">
        <i class="icon-social icon-cms-social-share" />
        <span class="share-linkName">{{ $t('clipboard') }}</span>
      </a>
    </div>
    <div
      :class="{ 'share-copyMessage--visible': showCopyMessage }"
      class="share-copyMessage">
      Copié au presse-papier
    </div>
  </div>
</template>

<script>

export default {
  name: 'ShareButton',

  props: {
    url: {
      type: String,
      required: true
    },
    classes: {
      type: String,
      default: ''
    },
    sharedWishlistUrl: {
      type: URL,
      default: null
    }
  },

  data () {
    return {
      toggleShareLinks: false,
      showCopyMessage: false,
      finalUrl: this.url
    }
  },

  methods: {
    closeLinks () {
      this.toggleShareLinks = false
    },

    copyLink () {
      if (this.sharedWishlistUrl) {
        this.copyToClipboard(this.sharedWishlistUrl)
        this.finalUrl = this.sharedWishlistUrl.href
      } else {
        this.copyToClipboard(this.finalUrl)
      }

      this.closeLinks()

      const _this = this

      _this.showCopyMessage = true

      setTimeout(function () {
        _this.showCopyMessage = false
      }, 5500)
    },
    copyToClipboard (text) {
      return navigator.clipboard.writeText(text)
        .then(() => {
          return text
        })
        .catch((err) => {
          console.error('Unable to copy text: ', err)
          return err
        })
    }
  },

  mounted() {
    if (this.sharedWishlistUrl) {
      this.finalUrl = encodeURIComponent(this.sharedWishlistUrl)
    }
  }
}
</script>

<template>
  <div class="cc-badge">
    <slot />
    <transition name="cc-zoom-in-center">
      <component
        :is="isNotSup ? 'span' : 'sup'"
        v-show="!hidden && (content || content === 0 || isDot)"
        class="cc-badge-content"
        :class="[
          type ? 'cc-badge-content--' + type : null,
          {
            'is-fixed': $slots.default,
            'is-dot': isDot
          }
        ]"
        v-text="content" />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'CCBadge',

  props: {
    value: [String, Number],
    max: Number,
    isDot: Boolean,
    hidden: Boolean,
    isNotSup: Boolean,
    type: {
      type: String,
      validator (val) {
        return ['primary', 'success', 'warning', 'info', 'danger'].indexOf(val) > -1
      }
    }
  },

  computed: {
    content () {
      if (this.isDot) return

      const value = this.value
      const max = this.max

      if (typeof value === 'number' && typeof max === 'number') {
        return max < value ? `${max}+` : value
      }

      return value
    }
  }
}
</script>

<template>
  <div>
    <SelectField
      v-model="value"
      :options="options"
      @update:modelValue="updateValue" />
    <div v-if="shouldShowOtherField" class="cc-form-input--other">
      <cc-form-item
        :label="otherLabel"
        :name="otherFieldName"
        :error-path="errorPath">
        <InputField :id="otherFieldName" v-model="otherValue" />
      </cc-form-item>
    </div>
  </div>
</template>

<script>
import InputField from '@vanillaComponents/form_fields/Input.vue'
import SelectField from '@vanillaComponents/form_fields/Select.vue'
import FormError from '@vanillaUtils/form/FormError'
import { isEmpty } from '@vanillaUtils/Var'

export default {
  name: 'FormFieldSelect',

  components: {
    InputField,
    SelectField
  },

  mixins: [FormError],

  props: {
    input: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      value: null,
      otherValue: null
    }
  },

  computed: {
    options () {
      return Object.values(this.input.field_options).map(attributeValue => {
        return { value: attributeValue.id, label: attributeValue.name?.[this.$locale] }
      })
    },
    selectedOption () {
      if (this.value) {
        return this.input.field_options.find(option => option.id === this.value)
      }

      return null
    },
    otherLabel () {
      return this.selectedOption?.other_label?.[this.$locale] || this.$t('specify')
    },
    otherFieldName () {
      if (this.selectedOption) {
        return `${this.input.id}.other`
      }
      return null
    },
    shouldShowOtherField () {
      return this.selectedOption && this.selectedOption.other
    }
  },

  watch: {
    otherValue: {
      handler: 'updateValue',
      deep: true
    }
  },

  methods: {
    updateValue () {
      this.input.value = {
        ...(this.value ? { value: this.value } : {}),
        ...(!isEmpty(this.otherValue) ? { other: this.otherValue } : {})
      }

      this.clearError(this.input.id)
      this.clearError(this.otherFieldName)
    }
  }
}
</script>

<template>
  <div>
    <cc-form-item
      :label="input.name[$locale]"
      :name="`${input.id}.address.address`"
      :error-path="errorPath"
      required>
      <InputField
        :id="`${input.id}.address.address`"
        v-model="value.address"
        @input="clearError(`${input.id}.address.address`)" />
    </cc-form-item>
    <cc-form-item
      :label="$t('address.address2')"
      :name="`${input.id}.address.address2`"
      :error-path="errorPath">
      <InputField
        :id="`${input.id}.address.address2`"
        v-model="value.address2"
        @input="clearError(`${input.id}.address.address2`)" />
    </cc-form-item>
    <cc-form-item
      :label="$t('address.postal_code')"
      :name="`${input.id}.address.postal_code`"
      :error-path="errorPath"
      required>
      <InputField
        :id="`${input.id}.address.postal_code`"
        v-model="value.postal_code"
        maxlength="7"
        @input="clearError(`${input.id}.address.postal_code`)" />
    </cc-form-item>
    <cc-form-item
      :label="$t('address.city')"
      :name="`${input.id}.address.city`"
      :error-path="errorPath"
      required>
      <InputField
        :id="`${input.id}.address.city`"
        v-model="value.city"
        @input="clearError(`${input.id}.address.city`)" />
    </cc-form-item>
    <cc-form-item
      :label="$t('address.province')"
      :name="`${input.id}.address.province`"
      :error-path="errorPath"
      required>
      <InputField
        :id="`${input.id}.address.province`"
        v-model="value.province"
        @input="clearError(`${input.id}.address.province`)" />
    </cc-form-item>
    <cc-form-item
      :label="$t('address.country')"
      :name="`${input.id}.address.country`"
      :error-path="errorPath"
      required>
      <InputField
        :id="`${input.id}.address.country`"
        v-model="value.country"
        @input="clearError(`${input.id}.address.country`)" />
    </cc-form-item>
  </div>
</template>

<script>
import FormError from '@vanillaUtils/form/FormError'
import InputField from '@vanillaComponents/form_fields/Input.vue'

export default {
  name: 'FormFieldAddress',

  components: { InputField },

  mixins: [FormError],

  props: {
    input: {
      type: Object,
      required: true
    }
  },

  computed: {
    value () {
      return this.input.value.address
    }
  },

  created () {
    this.input.value = {
      address: {}
    }
  }
}

</script>

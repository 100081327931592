function initPhoneTrigger () {
  const phone = document.getElementById('mainHeader-contact')
  if (phone) {
    const phonePageLink = phone.dataset.contactCp

    if (window.innerWidth < 750) {
      phone.href = 'tel:18335644726'
      phone.ariaLabel = 'Passer un appel'
    } else {
      phone.href = phonePageLink
      phone.ariaLabel = 'Vers page \'Nous joindre\''
    }

    phone.removeAttribute('data-contact-cp')
  }
}

export default initPhoneTrigger

import extender from '@vanillaUtils/Extender'
import DomHelper from '../DomHelperExtend'
import Listeners from '../ListenersExtend'

class Video extends extender(DomHelper, Listeners) {
  static getDomClasses () {
    return {
      start: 'js-startVideo',
      video: 'js-video'
    }
  }

  init () {
    this.elements.start.addEventListener('click', event => {
      this.elements.video.setAttribute('src', this.elements.video.dataset.src)
      this.elements.start.classList.add('media-startVideo--remove')

      // TODO remove on video load instead https://developers.google.com/youtube/iframe_api_reference#Events
      this.elements.start.addEventListener('transitionend', () => {
        this.elements.start.remove()
      }, { once: true })
    }, { once: true })
  }
}

export default Video

// import Alert from '@vanillaUtils/Alert'
import axios from 'axios'
import { useToast } from 'vue-toastification'

const toast = useToast()

export default error => {
  if (axios.isCancel(error)) {
    return
  }

  if (!error.response) {
    throw error
  }

  let message = ''
  const remoteErrors = error.response?.data?.errors

  if (remoteErrors instanceof Object && !!remoteErrors) {
    message = Object.values(remoteErrors).shift().join('')
  } else {
    if (error?.response?.status === 415) {
      message = window.app?.$i18n?.t('malicious_file_detected')
    } else {
      message = error.response.data?.message
    }
  }

  // TODO: implement l'utilisation d'erreur pour le formulaire?
  // Alert.error('An error happened', message, {
  //   dangerouslyUseHTMLString: true
  // })
  toast.error(message)
  if (message) {
    console.error(message)
    return message
  }
  return error
}

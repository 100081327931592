import AnalyticsHub from '@vanillaUtils/AnalyticsHub'

export default class AnalyticPageViewManager {
  static register () {
    return new this()
  }

  refresh () {
  }

  ready () {
    const items = []
    const dom = document.getElementsByClassName('js-analytics-items')
    for (let i = 0; i < dom.length; i++) {
      AnalyticsHub.addPosition(dom[i])
      items.push(AnalyticsHub.buildEventData(dom[i]))
    }
    AnalyticsHub.push({
      event: 'page_view',
      ...(items.length ? {items: items} : {})
    })
  }
}

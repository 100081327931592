/**
 * @author Sebastien Rodrigue
 */
import FormError from './FormError'
import Functions from '@vanilla/utils/Functions.js'

export default {
  mixins: [FormError],

  provide () {
    const _this = this

    return {
      label: _this.label,
      slugLabel: Functions.slugifyStr(_this.label),
    }
  },

  props: {
    name: {
      type: String,
      default: null
    },
    customError: {
      type: String,
      default: null
    }
  },

  computed: {
    inputErrorPath () {
      return this.suffixedErrorPath + this.name
    },
    error () {
      if (this.customError) {
        return this.customError
      }

      if (this.name && this.formErrors.has(this.inputErrorPath) !== false) {
        return this.formErrors.get(this.inputErrorPath)
      }

      return null
    },
    hasInputError () {
      return this.formErrors.has(this.inputErrorPath) !== false
    }
  },

  methods: {
    forgetInputError () {
      this.formErrors.forget(this.inputErrorPath)
    }
  }
}

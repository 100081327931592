
/**
 * Builder auto generated file
 * DO NOT EDIT/DELETE
 **/
import VanillaMain from '@vanilla/VanillaMain.export.js'
import VanillaBootstrap from '@vanilla/VanillaBootstrap.export.js'
import vanillaComponents from '@vanilla/components/vanillaComponents.export.js'
import Paralax from '@vanillaVBContentTypes/website/shared/parallax/js/Paralax.export.js'
import Share from '@vanillaVBContentTypes/website/sub-types/share/templates/default/js/Share.export.js'
import AlertTop from '@vanillaVBContentTypes/website/slots/alert-top/templates/default/js/AlertTop.export.js'
import Slideshow from '@vanillaVBContentTypes/website/layouts/columns/templates/carousel/js/Slideshow.export.js'
import Tabs from '@vanillaVBContentTypes/website/blocks/tabs/templates/default/js/Tabs.export.js'
import SelectDropdown from '@vanillaVBContentTypes/website/blocks/quick-menu/templates/default/js/SelectDropdown.export.js'
import PageListComponent from '@vanillaVBContentTypes/website/blocks/page-list/templates/default/js/PageListComponent.export.js'
import MediaVideo from '@vanillaVBContentTypes/website/blocks/media/templates/video/js/MediaVideo.export.js'
import ExpandComponent from '@vanillaVBContentTypes/website/blocks/accordeon/templates/default/js/ExpandComponent.export.js'
import ExpandItemComponent from '@vanillaVBContentTypes/website/blocks/accordeon/sub-types/expand/templates/default/js/ExpandItemComponent.export.js'
export default { VanillaMain, VanillaBootstrap, vanillaComponents, Paralax, Share, AlertTop, Slideshow, Tabs, SelectDropdown, PageListComponent, MediaVideo, ExpandComponent, ExpandItemComponent }

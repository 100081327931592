import Parallax from './Parallax'
import extender from '@vanillaUtils/Extender'
import DomHelper from '@vanilla/dom_modules/DomHelperExtend'
import Managers from '@vanilla/dom_modules/ManagersExtend'

class ParallaxManager extends extender(DomHelper, Managers) {
  static getDomClasses () {
    return {
      parallaxes: 'js-parallax'
    }
  }

  getModel (element) {
    return new Parallax({ domRoot: element })
  }
}

export default ParallaxManager


export default {
  props: {
    dataGtmCreativeSlot: {
      type: String,
      default: null
    }
  },

  computed: {
    analyticProps () {
      return {
        'data-gtm-creative-slot': this.dataGtmCreativeSlot
      }
    }
  }
}

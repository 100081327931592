import extender from '@vanillaUtils/Extender'
import DomHelper from '@vanilla/dom_modules/DomHelperExtend'
import Listeners from '@vanilla/dom_modules/ListenersExtend'
import simpleParallax from 'simple-parallax-js'

class Parallax extends extender(DomHelper, Listeners) {
  static getDomClasses () {
    return {
      parallaxContent: 'js-parallax-content'
    }
  }

  init () {
    new simpleParallax(this.elements.parallaxContent, this.getConfiguration())
  }

  getConfiguration () {
    return {
      orientation: this.domRoot.dataset.orientation,
      scale: this.domRoot.dataset.scale,
      overflow: this.domRoot.dataset.overflow === '1',
      delay: this.domRoot.dataset.delay
    }
  }
}

export default Parallax

<template>
  <input
    :id="id"
    :value="modelValue"
    :name="id"
    class="cc-textInput"
    @maska="handleChange"
    v-maska
    :data-maska="mask">
</template>

<script>
export default {
  name: 'MaskInput',

  props: {
    modelValue: {
      type: [String, Number, null],
      default: null,
      required: true
    },
    id: {
      type: String,
      default: null
    },
    mask: {
      type: String,
      default: null
    }
  },

  methods: {
    handleChange (event) {
      this.$emit('update:modelValue', event.detail.unmasked)
    }
  }
}
</script>

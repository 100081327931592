import Slideshow from './Slideshow'
import CoreSlideshowManager from '@vanilla/dom_modules/slideshow/SlideshowManager'

class SlideshowManager extends CoreSlideshowManager {
  static getDomClasses () {
    return {
      slideshows: 'js-carousel'
    }
  }

  getModel (element) {
    return new Slideshow({ domRoot: element })
  }
}

export default SlideshowManager

<template>
  <div :class="`cc-tip--${type}`" class="cc-tip">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CCTip',

  props: {
    type: {
      type: String,
      default: 'info' // info|warning|danger
    }
  }
}
</script>

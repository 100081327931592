<template>
  <div>
    <slot v-if="disabled" />
    <teleport v-else to="#app">
      <div
        class="vbmodal-overlay"
        :class="{'vbmodal-hidden': !displayModal}"
        tabindex="0"
        @keyup.esc="close"
        @click.self="close">
        <div
          class="vbmodal"
          :class="computedClasses"
          :style="width ? `width: ${width};` : ''"
          @keyup.esc="close">
          <div class="vbmodal-header">
            <div>
              <slot name="title">
                <span class="modalSlot-title">{{ title }}</span>
              </slot>
            </div>
            <div>
              <slot name="close">
                <button class="vbmodal-close" @click="close">
                  <i class="icon-cms-close" />
                </button>
              </slot>
            </div>
          </div>

          <div ref="content" class="vbmodal-content">
            <slot />
          </div>

          <div v-if="!!$slots['footer']" class="vbmodal-footer">
            <slot name="footer" :close="close" />
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>

import Scroll from '@vanillaUtils/Scroll'
import debounce from 'lodash/debounce'

export default {
  name: 'VBModal',

  emits: ['close', 'shown', 'mounted'],

  props: {
    disabled: Boolean,
    show: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: null
    },
    width: {
      type: String,
      default: null
    },
    classNames: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      displayModal: false,
      observer: null
    }
  },

  computed: {
    computedClasses () {
      return {
        'vbmodal--noTitle': !this.title,
        [this.classNames]: !!this.classNames
      }
    }
  },

  watch: {
    displayModal (newValue) {
      if (newValue && !this.disabled) {
        Scroll.disable()
        return
      }
      Scroll.enable()
    },
    disabled () {
      if (this.disabled) {
        Scroll.enable()
      }
    }
  },

  methods: {
    close () {
      this.$emit('close')
      this.displayModal = false
    },
    contentShown: debounce(function () {

      // Function is debounced since the modal might need multiple "frames" to display everything
      if (this.$refs.content.children?.length) {
        ManagerNotifier.refresh()
        if (this.observer) {
          this.observer.disconnect()
        }
      }
    }, 100)
  },

  created () {
    this.displayModal = this.show
  },

  beforeUnmount () {
    Scroll.enable()
    if (this.observer) {
      this.observer.disconnect()
    }
  },

  mounted () {
    const observer = new MutationObserver(this.contentShown)
    observer.observe(this.$refs.content, {
      childList: true,
      subtree: true
    })
    this.observer = observer
    this.$emit('mounted')
  }
}
</script>

import Swiper from 'swiper'
import { Autoplay, Navigation, Pagination, EffectFade } from 'swiper/modules'
import extender from '@vanillaUtils/Extender'
import DomHelper from '@vanilla/dom_modules/DomHelperExtend'
import Listeners from '@vanilla/dom_modules/ListenersExtend'

class Slideshow extends extender(DomHelper, Listeners) {
  static getDomClasses () {
    return {
      root: 'js-carousel',
      wrapper: 'js-carouselWrapper',
      slides: 'js-carouselSlide',
      next: 'js-carouselNext',
      previous: 'js-carouselPrevious',
      pagination: 'js-carouselPagination'
    }
  }

  init () {
    Array.from(this.elements.slides).forEach((currentSlide, index) => {
      currentSlide.dataset.index = index + 1
    })

    const dataConfig = this.domRoot.dataset || {}

    const swiperConfig = {
      modules: [Autoplay, Pagination, Navigation, EffectFade],
      speed: parseInt(dataConfig.speed || 500),
      slidesPerView: parseFloat(dataConfig.slidesPerViewMobile || 1),
      spaceBetween: parseInt(dataConfig.spaceBetweenSlidesMobile || 30),
      threshold: 15,
      autoplay: (dataConfig.autoplay === 'true' ? {
        delay: parseInt(dataConfig.delay),
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
        reverseDirection: !!dataConfig.direction
      } : (dataConfig.autoplay === 'continuous' ? {
        delay: 0,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
        reverseDirection: !!dataConfig.direction
      } : false)),
      fadeEffect: {
        crossFade: true
      },
      loop: dataConfig.autoplay === 'continuous',
      preloadImages: false,
      wrapperClass: this.classes.wrapper,
      slideClass: this.classes.slides,
      lazy: {
        loadOnTransitionStart: true
      },
      navigation: {
        nextEl: this.elements.next,
        prevEl: this.elements.previous
      },
      pagination: {
        el: this.elements.pagination,
        type: 'bullets',
        clickable: true
      },
      effect: dataConfig.effect,
      breakpoints: {}
    }

    swiperConfig.breakpoints[dataConfig.breakpoint] = {
      slidesPerView: parseFloat(dataConfig.slidesPerViewDesktop || 1),
      spaceBetween: parseInt(dataConfig.spaceBetweenSlides || 30)
    }

    this.swiperReference = new Swiper(this.domRoot, swiperConfig)
  }
}

export default Slideshow

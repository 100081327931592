<template>
  <div class="search">
    <div @click="toggleModal">
      <slot />
    </div>
    <vb-modal
      v-if="showModal"
      width="50%"
      @close="showModal = false"
      @mounted="focusInput">
      <div class="search-wrapper">
        <div class="search-form" :class="{'search--focused': focusedOrQuery}">
          <label for="searchInput" class="search-label" />
          <div :class="{ 'search--focused': focusedOrQuery }" class="search-form">
            <label class="search-label" for="searchInput">
              {{ $t('search') }}
            </label>
            <input
              ref="searchInput"
              v-model="query"
              class="search-input"
              tabindex="0"
              type="text"
              @focusin="toggleFocus"
              @focusout="toggleFocus">
          </div>
          <div class="search-resultsList">
            <transition-group name="resultsAnimation" tag="ul">
              <li
                v-for="(result, id) in results"
                :key="id"
                class="search-result">
                <a :href="result.url" class="search-resultTitle">{{ result.name }}</a>
              </li>
            </transition-group>
          </div>
        </div>
      </div>
    </vb-modal>
  </div>
</template>

<script>
import VbModal from './VBModal.vue'

export default {
  name: 'Search',

  components: { VbModal },

  data () {
    return {
      showModal: false,
      focused: false,
      query: '',
      results: [],
      controller: null
    }
  },

  computed: {
    focusedOrQuery () {
      return this.focused || this.query.length
    }
  },

  watch: {
    query (newValue) {
      if (!newValue.length) {
        this.results = []
        return
      }

      this.search()
    }
  },

  methods: {
    toggleModal () {
      this.showModal = !this.showModal
    },
    toggleFocus () {
      this.focused = event.type === 'focusin'
    },
    focusInput () {
      this.$refs.searchInput.focus()
    },
    search () {
      return this.$axios.get('/api/search',
        {
          params: { 'term': this.query },
          signal: this.getNewControllerSignal()
        }
      ).then(response => {
        this.results = response.data.hits
      })
    },
    getNewControllerSignal () {
      if (this.controller) {
        this.controller.abort()
      }

      this.controller = new AbortController()

      return this.controller.signal
    }
  }
}
</script>

const HEADER_SELECTOR = 'header'
const HEADER_SHOW_CLASS = 'header--show'
const BODY_SELECTOR = 'body'
const SCROLLED_DOWN_CLASS = 'header--scrolledDown'
const SCROLLED_UP_CLASS = 'header--scrolledUp'
const IMMERSION_WHITE_CLASS = 'immersion--white'
const IMMERSION_WHITE_CLASS_JS = 'immersion-js--white'
const IMMERSION_BLACK_CLASS = 'immersion--black'
const IMMERSION_BLACK_CLASS_JS = 'immersion-js--black'
const IMMERSION_CLASS = 'immersion'

class Immersion {
  constructor () {
    this.scrollPosition = window.scrollY

    this.headerBackground = document.querySelector(HEADER_SELECTOR)
    this.body = document.querySelector(BODY_SELECTOR)
    this.headerHeight = this.headerBackground?.offsetHeight

    this.verifyScroll()

    this.init()
  }

  verifyScroll () {
    this.scrollPos = window.scrollY

    if (this.scrollPos >= this.headerHeight) {
      this.addClassOnScroll()
    } else {
      this.removeClassOnScroll()
    }
  }

  init () {
    const that = this

    window.addEventListener('scroll', () => {
      this.verifyScroll()

      that.currentScrollPosition = window.scrollY

      if (that.currentScrollPosition <= 500) {
        that.body?.classList.remove(SCROLLED_UP_CLASS)
        that.body?.classList.remove(SCROLLED_DOWN_CLASS)
      } else if (that.currentScrollPosition >= that.scrollPosition) {
        that.body?.classList.remove(SCROLLED_UP_CLASS)
        that.body?.classList.add(SCROLLED_DOWN_CLASS)
      } else if (that.currentScrollPosition < that.scrollPosition) {
        that.body?.classList.remove(SCROLLED_DOWN_CLASS)
        that.body?.classList.add(SCROLLED_UP_CLASS)

        if (that.body?.classList.contains(IMMERSION_WHITE_CLASS_JS)) {
          that.body?.classList.remove(IMMERSION_WHITE_CLASS)
          that.body?.classList.remove(IMMERSION_CLASS)
        }

        if (that.body?.classList.contains(IMMERSION_BLACK_CLASS_JS)) {
          that.body?.classList.remove(IMMERSION_BLACK_CLASS)
          that.body?.classList.remove(IMMERSION_CLASS)
        }
      }

      if (that.body?.classList.contains(IMMERSION_WHITE_CLASS_JS) && that.currentScrollPosition <= 0) {
        that.body?.classList.add(IMMERSION_WHITE_CLASS)
        that.body?.classList.add(IMMERSION_CLASS)
      }

      if (that.body?.classList.contains(IMMERSION_BLACK_CLASS_JS) && that.currentScrollPosition <= 0) {
        that.body?.classList.add(IMMERSION_BLACK_CLASS)
        that.body?.classList.add(IMMERSION_CLASS)
      }

      that.scrollPosition = that.currentScrollPosition
    })
  }

  addClassOnScroll () {
    this.headerBackground?.classList.add(HEADER_SHOW_CLASS)
  }

  removeClassOnScroll () {
    this.headerBackground?.classList.remove(HEADER_SHOW_CLASS)
  }
}

export default Immersion

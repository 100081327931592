<template>
  <DateField
    :id="input.id"
    v-model="input.value"
    type="date" />
</template>

<script>
import DateField from '@vanillaComponents/form_fields/Date.vue'

export default {
  name: 'FormFieldDate',

  components: { DateField },

  props: {
    input: {
      type: Object,
      required: true
    }
  }
}
</script>

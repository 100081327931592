<template>
  <CheckboxField
    :id="input.id"
    v-model="input.value"
    :label="label" />
</template>

<script>
import CheckboxField from '@vanillaComponents/form_fields/Checkbox.vue'

export default {
  name: 'FormFieldBoolean',

  components: { CheckboxField },

  props: {
    input: {
      type: Object,
      required: true
    }
  },

  computed: {
    label () {
      return this.input?.name?.[this.$locale]
    }
  }
}
</script>


import VideosManager from './VideosManager'

function init () {
  window.ManagerNotifier.register(VideosManager)
}

export {
  init
}

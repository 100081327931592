<template>
  <div class="cc-selectInput">
    <VueSelect
      v-model="localValue"
      :options="options"
      :disabled="disabled || readonly"
      :clearable="clearable"
      :placeholder="calculatedPlaceholder"
      :multiple="multiple"
      :data-gtm-input="id"
      :data-gtm-input-name="slugLabel"
      @update:model-value="onChange"
      @search:focus="$emit('focus', $event)" />
  </div>
</template>

<script>
import UsesObjectValues from './mixins/UsesObjectValues'
import FormField from './mixins/FormField'
import { isArray } from '@vanillaUtils/Var'

export default {
  name: 'CCSelectField',

  mixins: [UsesObjectValues, FormField],

  emits: ['update:modelValue'],

  props: {
    modelValue: {
      type: [String, Array, null],
      required: true,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      required: true
    },
    multiple: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    clearable: Boolean
  },

  data () {
    return {
      localValue: this.modelValue
    }
  },

  computed: {
    calculatedPlaceholder () {
      return this.placeholder ?? this.$t('pageList.inputPlaceholder')
    }
  },

  watch: {
    modelValue: {
      handler (value) {
        if (isArray(value)) {
          if (this.multiple) {
            this.localValue = value.reduce((accumulator, value) => {
              const option = this.getRelatedOption(value)

              if (option) {
                accumulator.push(option)
              }

              return accumulator
            }, [])
          }
        } else {
          this.localValue = this.getRelatedOption(value)
        }
      },
      immediate: true
    }
  },

  methods: {
    getRelatedOption (value) {
      return this.options.find(option => option.value === value)
    },
    onChange (value) {
      if (isArray(value)) {
        value = value.map(value => this.getValueOf(value))
      } else {
        value = this.getValueOf(value)
      }

      this.$emit('update:modelValue', value)
    }
  }
}
</script>

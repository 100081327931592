import AnalyticsHub from '@vanillaUtils/AnalyticsHub'

export default class AnalyticEventManager {
  static register () {
    return new this()
  }

  refresh () {
  }

  ready () {
    this.registerListener()
  }

  registerListener () {
    document.body.addEventListener('click', (e) => {
      this.handleClick(e)
    })

    // this event does bubble compared to 'focus', but now using different conditionSelector "data-gtm-input" to only consider inputs.
    document.body.addEventListener('focusin', (e) => {
      this.handleFocus(e)
    })
  }

  handleFocus (event) {
    const clickTarget = event.target.closest(this.getFocusConditionSelector())
    if (!clickTarget) return
    const eventData = AnalyticsHub.buildEventData(clickTarget)
    eventData.event = 'inputFocus'
    AnalyticsHub.push(eventData)
  }

  handleClick (event) {
    const clickTarget = event.target.closest(this.getConditionSelector())
    if (!clickTarget) return
    const eventData = AnalyticsHub.buildEventData(clickTarget)
    if (clickTarget.href?.includes('tel:') || clickTarget.href?.includes('mailto:')) {
      eventData.clickType = clickTarget.href.includes('tel:') ? 'phone' : 'email'
    }
    AnalyticsHub.push(eventData)
  }

  getFocusConditionSelector () {
    return '[data-gtm-input]'
  }

  getConditionSelector () {
    return '[data-gtm-event], [data-gtm-id]'
  }
}

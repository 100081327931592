
import { init as initAnalytics } from './analytics'
import { init as initSlideshow } from './slideshow'
import { init as initVideo } from './video'
import { init as initDropdown } from './dropdown'
import { init as initImmersion } from './immersion'

function init () {
  initAnalytics()
  initSlideshow()
  initVideo()
  initDropdown()
  initImmersion()
}

export { init }

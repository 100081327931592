function initShare () {
  const shareIcons = document.querySelectorAll('.js-icon-share')

  shareIcons?.forEach(shareIcon => {
    shareIcon.addEventListener('click', () => {
      shareIcon.parentElement.classList.toggle('shares-container--show')
    })
  })
}

export default {
  ready: initShare
}

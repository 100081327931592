<template>
  <div
    class="cc-checkboxInput"
    :class="{
      'cc-checkboxInput--disabled': disabled,
      'cc-checkboxInput--inGroup': isGroup,
      'cc-checkboxInput--checked': checked
    }">
    <input
      :id="id"
      ref="input"
      v-model="localValue"
      :data-gtm-input="id"
      :data-gtm-input-name="slugLabel"
      :value="realValue"
      :disabled="(disabled && !checked) || readonly"
      class="cc-checkboxInput-input"
      :name="name"
      type="checkbox">
    <label :for="id" :class="`cc-checkboxInput-value--${getValueOf(value)}`">
      <slot name="label">
        <cc-html :html="label" />
      </slot>
    </label>
  </div>
</template>

<script>
import UsesObjectValues from './mixins/UsesObjectValues'
import FormField from './mixins/FormField'

export default {
  name: 'CCCheckboxField',

  mixins: [UsesObjectValues, FormField],

  props: {
    modelValue: {
      type: [String, Array, Boolean, null],
      required: true,
      default: null
    },
    value: {
      type: [String, Number, Object, null],
      default: null
    },
    label: {
      type: [Number, String],
      default: null
    },
    name: {
      type: [String],
      default: null
    },
    id: {
      type: String,
      default: null
    },
    disabled: Boolean,
    readonly: Boolean
  },

  data () {
    return {
      checkboxGroup: null
    }
  },

  computed: {
    realValue () {
      return this.getValueOf(this.value)
    },
    checked () {
      if (this.isGroup) {
        return this.modelValue?.includes(this.realValue)
      }

      return !!this.$refs?.input?.checked
    },
    isGroup () {
      return !!this.checkboxGroup
    },
    localValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        if (value instanceof Array) {
          value = value.map(value => this.getValueOf(value))
        }

        this.$emit('update:modelValue', value)
      }
    }
  },

  created () {
    let parent = this.$parent

    while (parent) {
      if (parent.$options.name !== 'CCCheckboxGroup') {
        parent = parent.$parent
      } else {
        this.checkboxGroup = parent
        parent = null
      }
    }
  }
}
</script>

<template>
  <li :class="className" class="menuElement">
    <single-expand :expanded="showChildren">
      <template #label>
        <a
          :href="element.url"
          class="menuElement-link"
          :class="{'menuElement-link--active': isActive}">{{ element.name }}</a>
      </template>
      <template v-if="element.children.length" #content>
        <ul class="menuElement-list">
          <menu-element
            v-for="child in element.children"
            :key="child.page_id"
            :element="child"
            :level="level + 1" />
        </ul>
      </template>
    </single-expand>
  </li>
</template>

<script>
import SingleExpand from './SingleExpand.vue'

export default {
  name: 'MenuElement',

  components: { SingleExpand },

  props: {
    element: {
      type: Object,
      default: () => ({
        children: []
      })
    },
    level: {
      type: Number,
      default: 0
    }
  },

  computed: {
    className () {
      return {
        [`menuElement--level-${this.level}`]: true,
        ...(this.element.css_class ? { [this.element.css_class]: true } : {})
      }
    },
    hasChildren () {
      return this.element.children?.length > 0
    },
    showChildren () {
      return (this.element.display_children === 'always' || this.isActive) && this.hasChildren
    },
    isActive () {
      const currentId = document.querySelector('#currentId')?.value
      const ancestors = JSON.parse(document.querySelector('#ancestors')?.value || '[]')

      return currentId === this.element.id || ancestors?.includes(this.element.page_id)
    }
  },

  created () {
    if (this.element.display_children === 'newRoot') {
      this.element.display_children = 'expand' // TODO: implement newRoot (old active)
    }
  }
}
</script>

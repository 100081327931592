<template>
  <div
    v-if="!config.isDisabled"
    ref="security"
    class="formSecurity">
    <component
      :is="currentSecurityComponent"
      v-if="showSecurity && currentSecurityComponent"
      ref="securityComponent"
      @fallback="fallback"
      @loaded="$emit('loaded')" />
    <cc-form-error :error="error" />
  </div>
</template>

<script>

import SecurityTurnstile from './SecurityTurnstile.vue'
import SecurityRecaptcha from './SecurityRecaptcha.vue'
import FormField from '@vanilla/utils/form/FormField'

const components = {
  captcha: SecurityRecaptcha,
  turnstile: SecurityTurnstile
}

export default {
  name: 'FormSecurityManager',

  mixins: [FormField],

  inject: {
    formData: {
      default: () => ({})
    }
  },

  props: {
    action: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      currentType: '',
      visibilityObserver: null,
      showSecurity: false
    }
  },

  computed: {
    config () {
      return window.Cheetah.formSecurity
    },
    isFallback () {
      return this.config.default === 'fallback'
    },
    type () {
      if (this.isFallback) {
        return this.currentType
      }

      return this.config.default
    },
    currentSecurityComponent () {
      return components[this.type] || false
    }
  },

  methods: {
    fallback () {
      if (this.isFallback) {
        const driversFallback = this.config.config[this.config.default]
        const indexOfCurrentType = driversFallback.indexOf(this.currentType)

        if (indexOfCurrentType + 1 < driversFallback.length) {
          this.currentType = driversFallback[indexOfCurrentType + 1]
        }
        return
      }
      this.currentType = false
      this.formErrors.add('security', this.$t('errors.unknown'))
    },
    reset () {
      this.$refs.securityComponent?.reset()
    }
  },

  mounted () {
    if (this.isFallback) {
      this.currentType = this.config.config.fallback[0]
    } else {
      this.currentType = this.config.default
    }

    if (this.config.isDisabled) {
      this.formData['security'] = {
        response: null,
        type: 'disabled'
      }

      this.$emit('loaded')
    } else {
      this.visibilityObserver = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          this.showSecurity = true
        }
      })

      this.visibilityObserver.observe(this.$refs.security)
    }
  },

  beforeUnmount () {
    if (this.visibilityObserver) {
      this.visibilityObserver.disconnect()
      this.visibilityObserver = null
    }
  }
}
</script>

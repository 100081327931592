
function initVideo () {
  document.querySelectorAll('.js-media-videoImage').forEach(hoverImage => {
    hoverImage.addEventListener('click', (event) => {
      const video = event.target.parentNode.querySelector('.js-media-video')
      hoverImage.style.display = 'none'

      if (video) {
        video.style.display = 'block'
      }
    })
  })
}

export default {
  ready: initVideo
}


<script>
import FormField from '@vanilla/utils/form/FormField'
import { useToast } from 'vue-toastification'

export default {
  name: 'CcFormToastError',

  mixins: [FormField],

  props: {
    error: {
      type: String,
      default: null
    },
    addCloseButton: Boolean
  },

  data () {
    return {
      toast: useToast(),
      toastId: null
    }
  },

  watch: {
    error (newValue) {
      console.log('(FormToastError:error) this.toast: ', this.toast)
      if (!newValue && this.toastId) {
        this.toast.dismiss(this.toastId)
        this.toastId = null
      }
      if (newValue && !this.toastId) {
        this.toastId = this.toast.error(this.error, {
          position: 'bottom-right',
          timeout: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: this.addCloseButton ? 'button' : false,
          icon: true,
          rtl: false
        })
      }
    }
  }
}
</script>

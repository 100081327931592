
import snakeCase from 'lodash/snakeCase'

class Model {
  constructor (data = null) {
    this.uid = getUid()

    Object.defineProperty(this, '_attributes', {
      value: {},
      writable: true,
      enumerable: true,
      configurable: true
    })

    /**
     * LARAVEL ACCESSORS & MUTATORS
     * To use it, define a function in the Model
     * following the Laravel naming convention
     * https://laravel.com/docs/master/eloquent-mutators
     */
    this.defineMutators()

    const { _attributes, uid, ...dataAfterOmission } = JSON.parse(JSON.stringify({ ...this.defaultValue, ...(data || {}) }))
    Object.assign(this, dataAfterOmission)
  }

  getMutatorMethods () {
    const properties = Object.getOwnPropertyNames(Object.getPrototypeOf(this)).filter(prop => prop !== 'constructor')

    /**
     * Return an object
     * the key's of the object is set to the attributes name
     * and it contains objects with the type as a key and the method as a value
     * eg: { name:{ 'set': 'setNameAttribute', 'get': 'getNameAttribute' } }
     */
    return properties.reduce((methods, value) => {
      const information = /^(set|get)(.*)Attribute$/.exec(value)

      if (information) {
        if (!methods[snakeCase(information[2])]) {
          methods[snakeCase(information[2])] = {}
        }

        methods[snakeCase(information[2])][information[1]] = information[0]
      }

      return methods
    }, {})
  }

  defineMutators () {
    const mutators = this.getMutatorMethods()

    Object.keys(mutators).forEach(attributeName => {
      const mutator = mutators[attributeName]

      // Setter and getter to retrieve Accessors and/or set Mutators
      Object.defineProperty(this, attributeName, {
        enumerable: true,
        get: mutator['get']
          ? () => this[mutator['get']](this._attributes[attributeName])
          : () => this._attributes[attributeName],
        set: mutator['set']
          ? (newValue, oldValue) => { this[mutator['set']](newValue, oldValue) }
          : newValue => { this._attributes[attributeName] = newValue }
      })
    })
  }

  serializeData () {
    return JSON.parse(JSON.stringify(this))
  }

  toJSON () {
    const { _attributes, uid, ...thisAfterOmission } = this
    return thisAfterOmission
  }

  get defaultValue () {
    return {}
  }
}

let uidCount = 0

function getUid () {
  return ++uidCount
}

export default Model

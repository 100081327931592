export default function (Model) {
  class ManagersExtend extends Model {
    static register () {
      return new this()
    }

    runInit () {
      this.listeners?.forEach(listener => {
        listener.runInit()
      })
    }

    refresh () {
      this.findAllElements()
      this.runInit()
      this.afterRefresh()
    }

    getModel () {
      this._WARNING('getModel')
    }

    get listeners () {
      return Object.values(this.elements).flat()
    }

    ready () {
      this.findAllElements()
      this.runInit()
      this.afterReady()
    }

    // Define these "afters" only if needed
    afterReady () {}

    afterRefresh () {
      this.afterReady()
    }

    _WARNING (fName = 'unknown method') {
      console.error('WARNING! "' + fName + '" is not overridden in ' + this.constructor.name)
    }
  }

  return ManagersExtend
}

import SlideshowManager from './SlideshowManager'

function registerSlideshow () {
  window.ManagerNotifier.register(SlideshowManager)
  window.ManagerNotifier.refresh()
}

export default {
  ready: registerSlideshow
}

<template>
  <teleport to="#app">
    <transition
      name="fade"
      @after-enter="afterTransitionEnter"
      @after-leave="$emit('closed')">
      <div
        v-if="displayOverlay"
        class="cc-modal-overlay"
        @click.self="$emit('close')">
        <transition name="scale" @after-leave="displayOverlay = false">
          <div v-if="displayModal" class="cc-modal">
            <slot name="close">
              <button class="cc-modal-close" @click="$emit('close')">
                X
              </button>
            </slot>
            <div class="cc-modal-content">
              <slot />
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </teleport>
</template>

<script>
/**
 * To show set showModal: true
 * Event @close is sent if overlay or close is clicked this event is sent for the parent to set showModal: false
 *     The reason for this is to allow the parent to make an action before the modal actually closes
 * Event @closed is sent at AFTER all animations are finished
 *     The reason is to define the "safe" moment to remove the modal in the parent if necessary
 */

import Scroll from '@vanillaUtils/Scroll'

export default {
  name: 'CCModal',

  emits: ['close', 'closed', 'shown'],

  props: {
    showModal: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      displayOverlay: false,
      displayModal: false
    }
  },

  watch: {
    showModal (newValue) {
      if (newValue) {
        Scroll.disable()
        this.displayOverlay = true
        return
      }

      Scroll.enable()
      this.displayModal = false
    }
  },

  methods: {
    afterTransitionEnter () {
      this.displayModal = true
      this.$nextTick(() => {
        this.$emit('shown')
      })
    }
  }
}
</script>

<template>
  <cc-popper ref="popper" v-bind="popperProps">
    <template #reference>
      <div class="cc-infoIcon">
        <i :class="icon" />
      </div>
    </template>
    <div class="cc-infoIcon-content">
      <slot />
    </div>
  </cc-popper>
</template>

<script>
export default {
  name: 'CCInfoIcon',

  props: {
    icon: {
      type: String,
      default: 'icon-cms-information'
    },
    hideArrow: Boolean,
    offset: {
      type: Array,
      default: null
    },
    maxWidth: {
      type: String,
      default: 'none'
    },
    trigger: {
      type: String,
      default: 'hover'
    },
    placement: {
      type: String,
      default: 'top'
    },
    popperReference: {
      type: Object,
      default: null
    }
  },

  computed: {
    popperProps () {
      return {
        reference: this.popperReference,
        placement: this.placement,
        trigger: this.trigger,
        'max-width': this.maxWidth,
        offset: this.offset,
        'hide-arrow': this.hideArrow
      }
    }
  }
}
</script>

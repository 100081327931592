
export default function (Model) {
  class DomHelperExtend extends Model {
    constructor (props) {
      super(props)

      this._classes = this._classes || {}
      this._selectors = this._selectors || {}
      this._elements = this._elements || {}
      this.initialised = false
      this.selectors = this.constructor.getDomClasses()
    }

    static getDomClasses () {
      this._WARNING('getDomClasses')
    }

    get classes () {
      return this._classes
    }

    get selectors () {
      return this._selectors
    }

    get elements () {
      return this._elements
    }

    set selectors (selectors) {
      Object.entries(selectors).forEach((nameValue) => {
        const [name, value] = nameValue
        this._classes[name] = value
        this._selectors[name] = '.' + value
      })
    }

    findAllElements () {
      const context = this.isManager ? document : this.domRoot

      Object.entries(this.selectors).forEach((nameValue) => {
        const [name, value] = nameValue
        const elements = context.querySelectorAll(this.selectors[name])

        if (this.isManager) {
          elements.forEach(element => {
            element.classList.remove(this.classes[name])

            this.elements[name] = this._elements[name] || []
            this._elements[name].push(this.getModel(element))
          })
        } else {
          this.elements[name] = elements.length > 1 ? [...elements] : elements.item(0)
        }
      })
    }

    get isManager () {
      return typeof this.getModel === 'function'
    }

    _WARNING (fName = 'unknown method') {
      console.error('WARNING! "' + fName + '" is not overridden in ' + this.constructor.name)
    }
  }

  return DomHelperExtend
}

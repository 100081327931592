
import AnalyticsManager from './AnalyticsManager'
import AnalyticEventManager from './AnalyticEventManager'
import AnalyticPageViewManager from './AnalyticPageViewManager'

function init () {
  window.ManagerNotifier.register(AnalyticPageViewManager)
  window.ManagerNotifier.register(AnalyticsManager)
  window.ManagerNotifier.register(AnalyticEventManager)
}

export {
  init
}

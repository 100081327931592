<template>
  <div v-if="visible" class="cc-form-error">
    <template v-if="$slots.default">
      <slot />
    </template>
    <template v-else-if="hasError">
      <template v-if="isArray">
        <div v-for="err in error" :key="err">
          {{ err }}
        </div>
      </template>
      <template v-else>
        {{ error }}
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CCFormError',

  props: {
    error: {
      type: String,
      default: null
    }
  },

  computed: {
    isArray () {
      return this.error instanceof Array
    },
    hasError () {
      return !!this.error
    },
    visible () {
      return this.hasError || this.$slots.default
    }
  }
}
</script>

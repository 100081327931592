<template>
  <div class="modalButton">
    <button
      style="cursor: pointer;"
      :class="classNames"
      v-bind="dataProperties"
      :aria-label="ariaLabel"
      @click="openDialog">
      <slot />
    </button>
    <component
      :is="modalComponent"
      v-if="modalComponent"
      @close="modalComponent = null" />
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ModalButton',

  props: {
    classNames: {
      type: String,
      required: true
    },
    ariaLabel: {
      type: String,
      default: null
    },
    modalId: {
      type: String,
      required: true
    },
    dataProperties: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      modalComponent: null,
      finalModalId: this.modalId
    }
  },

  methods: {
    openDialog () {
      if (this.finalModalId === '') {
        this.finalModalId = document.getElementById('projectType').dataset.projectType // needed to dynamically set modal with correct project type
      }

      return axios.get('/api/modal/' + this.finalModalId)
        .then(response => {
          const fakeDOM = document.createElement('div')
          fakeDOM.innerHTML = response.data
          this.extractStyle(fakeDOM)

          this.modalComponent = {
            template: fakeDOM.innerHTML
          }
        })
    },
    extractStyle (DOM) {
      const styleCollection = DOM.getElementsByTagName('style')

      if (styleCollection.length) {
        const styleId = 'modal-style-' + this.modalId
        const exists = document.getElementById(styleId)
        const style = document.createElement('style')
        style.id = styleId
        style.innerHTML = styleCollection[0].innerHTML
        styleCollection[0].remove()

        if (!exists) {
          document.getElementsByTagName('head')[0].appendChild(style)
        }
      }
    }
  }
}
</script>

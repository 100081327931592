<template>
  <div class="cc-form-inputGroup">
    <div class="cc-fileInput-header">
      <div class="cc-fileInput" :class="{ 'cc-fileInput--disabled': disabled }">
        <button type="button">
          {{ $t('upload_files', maxFileCount) }}
        </button>
        <input
          :id="input.id"
          ref="inputType"
          type="file"
          multiple="multiple"
          :name="input.id"
          :data-gtm-input="input.id"
          :data-gtm-input-name="slugLabel"
          :accept="validFileExtensions"
          :disabled="disabled"
          @change="setFiles">
      </div>
      <span v-if="loading" class="cc-fileInput-loadingMessage">
        {{ $t('uploading') }}
      </span>
    </div>
    <div class="text-muted">
      <em>{{ $t('file_text_criteria', { count: maxFileCount, maxCount: maxFileCount, maxSize: maxFileSize, extension: validFileExtensionsText }) }}</em>
    </div>
    <ul v-if="uploadedFiles.length" class="cc-fileInput-list">
      <li
        v-for="file in uploadedFiles"
        :key="file.id"
        class="cc-fileInput-item">
        <span class="cc-fileInput-itemLabel">{{ file.name }}</span>
        <span class="cc-fileInput-itemTrash" @click="removeFile(file)" />
      </li>
    </ul>
  </div>
</template>

<script>
import FormError from '@vanillaUtils/form/FormError'
import FormField from '../../form_fields/mixins/FormField.js'
import trim from 'lodash/trim'

export default {
  name: 'FormFieldFile',

  mixins: [FormError, FormField],

  props: {
    input: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      uploadedFiles: [],
      loading: false
    }
  },

  computed: {
    disabled () {
      return this.loading
    },
    validFileExtensions () {
      return this.fileExtensions.map(extension => {
        return `.${trim(extension.toLowerCase(), '.')}`
      }).join(',')
    },
    validFileExtensionsText () {
      return this.fileExtensions.map(extension => {
        return `${trim(extension.toUpperCase(), '.')}`
      }).join(', ')
    },
    fileExtensions () {
      return window.Cheetah?.form.fileExtensions
    },
    maxFileSize () {
      return window.Cheetah?.form.maxFileSize
    },
    maxFileCount () {
      return window.Cheetah?.form.maxFileCount
    }
  },

  methods: {
    removeFile (fileToRemove) {
      const uploadedFileIndex = this.uploadedFiles.findIndex(file => file.name === fileToRemove.name)
      this.uploadedFiles.splice(uploadedFileIndex, 1)
      this.input.value = this.uploadedFiles.map(file => file.id)
      this.$refs.inputType.value = null
    },
    setFiles (event) {
      this.uploadFiles(event.target.files)
    },
    uploadFiles (files) {
      const payloadFormData = new FormData()
      if (files !== null) {
        const currentFileIds = this.uploadedFiles.map(file => file.name)

        if ((Array.from(this.uploadedFiles)?.length + files.length) > this.maxFileCount) {
          this.addError(this.input.id, this.$t('too_many_files', { count: this.maxFileCount }))
        } else {
          Array.from(files).forEach((file, index) => {
            const fileExtension = file.name.split('.')[1]
            const filesize = ((file.size / 1024) / 1024).toFixed(4) // MB

            if (this.fileExtensions.includes(fileExtension.toLowerCase())) {
              if (filesize <= this.maxFileSize) {
                if (!currentFileIds.includes(file.name)) {
                  payloadFormData.append('bulk[][file]', file)
                }
              } else {
                this.addError(this.input.id, this.$t('file.is_too_large', { name: file.name, maxSize: this.maxFileSize }, 0))
              }
            } else {
              this.addError(this.input.id, this.$t('file.wrong_extension', { name: file.name }, 0))
            }
          })
        }
      }

      /**
       * There's a bug with form data and put requests with laravel.
       * This is a way to spoof the method and prevent the bug.
       *
       * @see https://stackoverflow.com/a/74472089/2324107
       */
      if (Array.from(payloadFormData.keys()).length > 0) {
        payloadFormData.append('_method', 'put')
        this.loading = true
        this.input.blockFormSubmit = true
        this.$axios.post('/api/form-files', payloadFormData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          const recentlyUploadedFiles = response.data.map(file => {
            return { 'id': file.id, 'name': file.filename }
          })
          this.uploadedFiles = [...this.uploadedFiles, ...recentlyUploadedFiles]
          this.input.value = this.uploadedFiles.map(file => file.id)
        }).catch((err) => {
          const message = RemoteErrors(err)
          this.addError(this.input.id, message)
        }).finally(() => {
          this.loading = false
          this.input.blockFormSubmit = false
        })
      }
    }
  }
}
</script>

<template>
  <input
    :id="id"
    v-model="localValue"
    :name="id"
    :min="min"
    :max="max"
    type="number"
    :disabled="disabled"
    :placeholder="placeholder"
    :readonly="readonly"
    class="cc-numberInput"
    :class="{ 'cc-numberInput--hideControls': hideControls }"
    @keydown="handleKeyDown">
</template>

<script>
import FormField from './mixins/FormField'

export default {
  name: 'CCNumberField',

  mixins: [FormField],

  props: {
    modelValue: {
      type: [String, Number, null],
      required: true,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    min: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    hideControls: Boolean,
    disabled: Boolean,
    readonly: Boolean
  },

  computed: {
    localValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },

  methods: {
    handleKeyDown (event) {
      if (event.key === 'e') {
        event.preventDefault()
      }
    }
  }
}
</script>

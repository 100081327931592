<template>
  <div class="cc-checkboxGroup cc-form-inputGroup">
    <i v-if="options.length === 0">
      {{ $t('no_options') }}
    </i>
    <div
      v-for="option in options"
      :key="option.id"
      class="cc-form-inputOption">
      <CheckboxField
        :id="option.id"
        v-model="value"
        :value="option.id"
        :label="getOptionValue(option)" />
      <div v-if="shouldShowOtherField(option)" class="cc-form-input--other">
        <cc-form-item
          :label="otherOptionTitle(option.id)"
          :name="getOtherErrorPath(option.id)"
          :error-path="errorPath">
          <InputField :id="getOtherErrorPath(option.id)" v-model="otherValues[option.id]" />
        </cc-form-item>
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxField from '@vanillaComponents/form_fields/Checkbox.vue'
import InputField from '@vanillaComponents/form_fields/Input.vue'
import FormError from '@vanillaUtils/form/FormError'
import { isEmpty } from '@vanillaUtils/Var'

export default {
  name: 'FormFieldCheckbox',

  components: {
    CheckboxField,
    InputField
  },

  mixins: [FormError],

  emits: ['input'],

  props: {
    input: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      value: [],
      otherValues: {}
    }
  },

  computed: {
    options () {
      if (this.input?.field_options?.length) {
        return this.input.field_options
      }

      return []
    }
  },

  watch: {
    otherValues: {
      handler: 'updateValue',
      deep: true
    },
    value: 'updateValue'
  },

  methods: {
    shouldShowOtherField (option) {
      return this.value.includes(option.id) && this.getOption(option.id).other
    },
    getOptionValue (option) {
      let optionValue = option.name

      if (option.name instanceof Object) {
        optionValue = option.name[this.$locale]
      }

      return optionValue
    },
    otherOptionTitle (optionId) {
      return this.getOption(optionId)?.other_label?.[this.$locale] || this.$t('specify')
    },
    getOption (optionId) {
      return this.options.find(option => option.id === optionId)
    },
    initOtherValue () {
      this.otherValues = this.input.field_options.reduce((acc, option) => {
        acc[option.id] = null

        return acc
      }, {})
    },
    getOtherErrorPath (optionId) {
      return `${this.input.id}.${optionId}.other`
    },
    updateValue () {
      this.input.value = this.value.reduce((acc, optionId) => {
        acc[optionId] = {
          value: optionId,
          ...(!isEmpty(this.otherValues[optionId]) ? { other: this.otherValues[optionId] } : {})
        }

        return acc
      }, {})

      this.clearError(this.input.id)
      Object.keys(this.otherValues).forEach(optionId => this.clearError(this.getOtherErrorPath(optionId)))
    }
  },

  created () {
    this.initOtherValue()
  }
}
</script>

export default {
  provide () {
    const _this = this

    return {
      formData: _this.dataToProtect
    }
  },

  computed: {
    dataToProtect () {
      throw new Error('This computed needs to be overwriten in Turnstile parent component')
    }
  }
}

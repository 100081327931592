<template>
  <div class="cc-form-input" :class="`cc-form-input--${type}`">
    <cc-form-item
      :label="label"
      :required="required"
      :name="field.id"
      :error-path="baseErrorPath">
      <template v-if="info" #label-append>
        <cc-info-icon
          hide-arrow
          trigger="click"
          :offset="[0,6]">
          <cc-html :html="info" />
        </cc-info-icon>
      </template>
      <component
        :is="formInputComponent(type)"
        :error-path="baseErrorPath"
        :input="field"
        @input="onInput" />
    </cc-form-item>
    <div v-if="description" class="cc-form-itemDescription">
      <cc-html :html="description" />
    </div>
  </div>
</template>

<script>
import FormFieldAddress from '@vanillaComponents/form/inputs/FormFieldAddress.vue'
import FormFieldBoolean from '@vanillaComponents/form/inputs/FormFieldBoolean.vue'
import FormFieldCheckbox from '@vanillaComponents/form/inputs/FormFieldCheckbox.vue'
import FormFieldDate from '@vanillaComponents/form/inputs/FormFieldDate.vue'
import FormFieldDateTime from '@vanillaComponents/form/inputs/FormFieldDateTime.vue'
import FormFieldEmail from '@vanillaComponents/form/inputs/FormFieldEmail.vue'
import FormFieldFile from '@vanillaComponents/form/inputs/FormFieldFile.vue'
import FormFieldNumber from '@vanillaComponents/form/inputs/FormFieldNumber.vue'
import FormFieldPhone from '@vanillaComponents/form/inputs/FormFieldPhone.vue'
import FormFieldPostalCode from '@vanillaComponents/form/inputs/FormFieldPostalCode.vue'
import FormFieldRadio from '@vanillaComponents/form/inputs/FormFieldRadio.vue'
import FormFieldSelect from '@vanillaComponents/form/inputs/FormFieldSelect.vue'
import FormFieldText from '@vanillaComponents/form/inputs/FormFieldText.vue'
import FormFieldTextarea from '@vanillaComponents/form/inputs/FormFieldTextarea.vue'
import FormField from '@vanilla/utils/form/FormField'

const inputMappingComponents = {
  Address: FormFieldAddress,
  Bool: FormFieldBoolean,
  Checkbox: FormFieldCheckbox,
  Date: FormFieldDate,
  DateTime: FormFieldDateTime,
  Email: FormFieldEmail,
  File: FormFieldFile,
  Number: FormFieldNumber,
  Phone: FormFieldPhone,
  PostalCode: FormFieldPostalCode,
  Radio: FormFieldRadio,
  Select: FormFieldSelect,
  Text: FormFieldText,
  TextArea: FormFieldTextarea
}

export default {
  name: 'CCFormInput',

  mixins: [FormField],

  props: {
    field: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      baseErrorPath: 'values'
    }
  },

  computed: {
    type () {
      return this.field.type
    },
    label  () {
      return this.field.name[this.$locale]
    },
    required () {
      return !!this.field.required
    },
    info () {
      return this.field?.info?.[this.$locale]
    },
    description () {
      return this.field?.description?.[this.$locale]
    }
  },

  methods: {
    onInput () {
      this.formErrors.forget(`${this.baseErrorPath}.${this.field.id}`)
    },
    formInputComponent (inputType) {
      if (inputType) {
        return inputMappingComponents[inputType]
      }

      return null
    }
  }
}
</script>

<template>
  <!-- Google recaptcha v2 -->
  <div v-if="config.key" class="securityRecaptcha-reCaptchaWrapper">
    <div ref="captcha" class="loginForm-reCaptcha" />
  </div>
</template>

<script>
/* global grecaptcha */
import FormField from '@vanilla/utils/form/FormField.js'
import LiveScriptLoader from '@vanilla/utils/LiveScriptLoader.js'

export default {
  name: 'SecurityRecaptcha',

  emits: ['loaded'],

  mixins: [FormField],

  data () {
    return {
      config: window.Cheetah.formSecurity.config.captcha,
      renderConfig: {
        'sitekey': window.Cheetah.formSecurity.config.captcha.key,
        'callback': this.onVerify,
        'expired-callback': this.reset
      },
      instanceId: null
    }
  },

  inject: {
    formData: {
      default: () => ({})
    }
  },

  methods: {
    onload () {
      if (!grecaptcha.hasOwnProperty('render')) {
        grecaptcha.ready(() => {
          this.render()
        })
        return
      }
      this.render()
    },
    render () {
      this.instanceId = window.grecaptcha.render(this.$refs.captcha, this.renderConfig)

      this.$emit('loaded')
    },
    onVerify (response) {
      this.formData.security.response = response
      if (this.formErrors.has('security')) {
        this.formErrors.forget('security')
      }
    },
    reset () {
      grecaptcha.reset(this.instanceId)
    }
  },

  mounted () {
    if (!this.config.key) {
      console.log('SecurityCaptcha: missing key to instantiate security')
    }

    LiveScriptLoader.load('https://www.google.com/recaptcha/api.js?render=explicit')
      .then(() => {
        this.onload()
      })

    this.formData['security'] = {
      response: null,
      type: 'captcha'
    }
  }
}
</script>


import DropdownManager from './DropdownManager'

function init () {
  new DropdownManager()
}

export {
  init
}

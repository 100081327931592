
<template>
  <div class="cc-form-item" :class="hasInputError ? 'cc-form-item--withError' : ''">
    <label class="cc-form-label" :for="name">
      {{ label }} <slot name="label-append" /><span v-if="required" class="cc-form-required">*</span>
    </label>
    <slot />
    <cc-form-error :error="error" />
  </div>
</template>
<script>
import FormField from '@vanilla/utils/form/FormField'

export default {
  name: 'CCFormItem',

  mixins: [FormField],

  props: {
    label: {
      type: String,
      default: null
    },
    required: Boolean
  }
}
</script>

/* global dataLayer */
import extender from '@vanillaUtils/Extender'
import DomHelper from '../DomHelperExtend'
import Managers from '../ManagersExtend'
import AnalyticPromotion from './AnalyticPromotion'
import AnalyticsHub from '../../utils/AnalyticsHub.js'

class AnalyticsManager extends extender(DomHelper, Managers) {
  static getDomClasses () {
    return {
      sections: 'js-promotion-viewed'
    }
  }

  getModel (element) {
    return new AnalyticPromotion({ domRoot: element })
  }

  afterReady () {
    this.sendViewedPromotion()
  }

  sendViewedPromotion () {
    this.listeners.forEach(listener => {
      if (listener.viewSent) {
        return
      }

      AnalyticsHub.push({ ecommerce: null }, false)
      AnalyticsHub.push({
        event: 'view_promotion',
        ecommerce: listener.itemData
      }, false)

      listener.viewSent = true
    })
  }
}

export default AnalyticsManager

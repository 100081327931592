import { setCookie } from '@vanillaUtils/Utils.js'

function initAlertTop () {
  const body = document.querySelector('body')
  const alertTop = document.querySelector('.alertTop')

  if (alertTop) {
    const closeAlertIcon = document.querySelector('.js-alertTop-close')

    if (closeAlertIcon) {
      closeAlertIcon.addEventListener('click', () => {
        alertTop.classList.add('alertTop-off')
        body.classList.remove('alertTop-on')

        setCookie(`dismiss_${alertTop.id}`, 'true', 30)
      })
    }
  }
}

export default {
  ready: initAlertTop
}

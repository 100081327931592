<template>
  <div
    :class="{ 'slidingPanel--open': menuOpened }"
    class="slidingPanel">
    <div
      v-if="$slots.button"
      class="slidingPanel-slot"
      @click="toggleMenu">
      <slot name="button" />
    </div>
    <div v-if="$slots.c2a" class="slidingPanel-c2a">
      <slot name="c2a" />
    </div>
    <cc-drawer
      ref="drawer"
      :hide-header="!$slots.close"
      :position="transitionSide"
      :drawer-class="drawerClasses"
      :data-gtm-creative-slot="dataGtmCreativeSlot"
      :context="context"
      @closed="closeMenu">
      <template v-if="$slots.close" #close>
        <slot name="close" />
      </template>
      <template v-if="$slots.content" #default>
        <slot name="content" />
      </template>
      <template v-if="$slots.footer" #footer>
        <slot name="footer" />
      </template>
    </cc-drawer>
  </div>
</template>

<script>
export default {
  name: 'SlidingPanel',

  props: {
    context: {
      type: String,
      default: ''
    },
    dataGtmCreativeSlot: {
      type: String,
      default: 'slidingPanel'
    },
    noPadding: Boolean,
    positionRight: Boolean // default is left
  },

  data () {
    return {
      menuOpened: false
    }
  },

  computed: {
    transitionSide () {
      return this.positionRight ? 'right' : 'left'
    },
    drawerClasses () {
      return `slidingPanel-drawer${this.noPadding ? ' slidingPanel-drawer--noPadding' : ''}${this.positionRight ? ' slidingPanel-drawer--positionRight' : ''}`
    }
  },
  watch: {
    menuOpened (value) {
      return value ? this.$refs.drawer.open() : this.$refs.drawer.close()
    }
  },

  methods: {
    toggleMenu () {
      if (!this.menuOpened) {
        this.openMenu()
        return
      }
      this.closeMenu()
    },
    openMenu () {
      this.menuOpened = true
    },
    closeMenu () {
      this.menuOpened = false
    }
  }
}
</script>

<template>
  <div v-show="!submitted" class="cc-form-wrapper">
    <!-- Do not delete vl-parent class. It's the vue-loading-overlay container class -->
    <form
      ref="formContainer"
      class="cc-form vl-parent"
      @submit.prevent>
      <div class="cc-form-inputList">
        <FormInput
          v-for="field in fields"
          :key="field.id"
          :field="field" />
      </div>

      <form-security-manager
        ref="formSecurityManager"
        :action="action"
        @loaded="captchaIsLoading = false" />

      <div class="cc-form-footer">
        <div class="cc-form-buttons">
          <button
            class="c2a c2a--main"
            :class="{'c2a--disabled': !canSubmit}"
            type="submit"
            @click="submit">
            {{ $t('submit') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import FormInput from './FormInput.vue'
import Form from '@vanillaUtils/form/Form'
import FormField from '@vanilla/models/FormField'
import AnalyticsHub from '@vanillaUtils/AnalyticsHub'
import FormSecurity from '@vanillaUtils/form/FormSecurity'
import FormSecurityManager from './security/FormSecurityManager.vue'

export default {
  name: 'CCForm',

  components: {
    FormInput,
    FormSecurityManager
  },

  mixins: [
    Form,
    FormSecurity,
  ],

  data () {
    return {
      fields: [],
      formData: {
        'values': {},
        'form_id': this.form.id,
        'page_id': window.document.getElementById('currentId')?.value,
        'page_complete_url': window.location.href,
        'language': this.$locale
      },
      submitted: false,
      formInstance: null,
      captchaIsLoading: true
    }
  },

  computed: {
    dataToProtect () {
      return this.formData
    },
    canSubmit () {
      return !this.fields.some(field => field.blockFormSubmit)
    }
  },

  methods: {
    getValuesFromInput () {
      return this.fields.reduce((acc, field) => {
        acc[field.id] = field.value

        return acc
      }, {})
    },
    submit () {
      if (!this.canSubmit) {
        return
      }
      const loader = this.$loading.show({
        container: this.$refs.formContainer
      })
      this.saving = true

      this.formData.values = this.getValuesFromInput()

      this.$axios.post('/api/form-entries', this.formData)
        .then(response => {
          this.$refs?.formContainer.closest('.vb-form')?.classList.add('cc-form--complete')
          this.submitted = true
          const element = window.document.getElementById('cc-' + this.form.id + '-confirmMessage')
          element.classList.add('cc-form-confirmMessage--active')
          this.$nextTick(_ => {
            window.scroll({
              top: element.offsetTop,
              behavior: 'smooth'
            })
          })

          AnalyticsHub.push({
            event: 'submit_form',
            form_type: this.form.name,
            form_success: true
          })
        }).catch(error => {
          this.handleError(error)

          AnalyticsHub.push({
            event: 'submit_form',
            form_type: this.form.name,
            form_success: false
          })
        }).finally(_ => {
          loader.hide()

          this.$refs.formSecurityManager.reset()

          this.$nextTick(_ => {
            this.saving = false
          })
        })
    }
  },

  created () {
    this.fields = this.form.fields.map(field => new FormField(field))

    const element = window.document.getElementById('cc-' + this.form.id + '-confirmMessage')

    if (element) {
      element.classList.remove('cc-form-confirmMessage--active')
    }
  }
}
</script>

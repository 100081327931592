
import Immersion from './Immersion'

function init () {
  new Immersion()
}

export {
  init
}

<template>
  <ul class="slidingPanel-elements">
    <menu-element
      v-for="menu in menus"
      :key="menu.page_id"
      :element="menu" />
  </ul>
</template>

<script>
import MenuElement from './MenuElement.vue'

export default {
  name: 'MenuMobileContent',
  components: {
    MenuElement
  },
  data () {
    return {
      menus: {}
    }
  },
  created () {
    if (document.querySelector('#mobile_menu_preload')) {
      this.$axios.get(document.querySelector('#mobile_menu_preload').attributes.href.value)
        .then(response => {
          this.menus = response.data
        })
    } else {
      throw new Error('Mobile link does not exist')
    }
  }
}
</script>

<template>
  <NumberField
    :id="input.id"
    v-model="input.value"
    hide-controls />
</template>

<script>
import NumberField from '@vanillaComponents/form_fields/Number.vue'

export default {
  name: 'FormFieldNumber',

  components: { NumberField },

  props: {
    input: {
      type: Object,
      required: true
    }
  }
}
</script>

<template>
  <MaskInput
    :id="id"
    v-model="value"
    :data-gtm-input="id"
    :data-gtm-input-name="slugLabel"
    mask="(###) ###-####" />
</template>

<script>
import MaskInput from '@vanillaComponents/form_fields/MaskInput.vue'
import FormField from './mixins/FormField'

export default {
  name: 'CCPhoneField',

  mixins: [FormField],

  components: { MaskInput },

  props: {
    id: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      value: null
    }
  }
}

</script>

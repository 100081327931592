
const scriptHistory = {}
class LiveScriptLoader {
  static load (url) {
    const urlToLoad = new URL(url)
    const loadName = `${urlToLoad.host}${urlToLoad.path}`
    if (Object.keys(scriptHistory).includes(loadName)) {
      return scriptHistory[loadName]
    }

    const scriptElement = document.createElement('script')

    scriptElement.setAttribute('src', urlToLoad.toString())
    scriptElement.setAttribute('async', '')
    scriptElement.setAttribute('defer', '')

    scriptElement.onload = createPromiseCallbackForScript(loadName)
    document.head.appendChild(scriptElement)

    return scriptHistory[loadName]
  }
}

function createPromiseCallbackForScript (loadName) {
  let resolveCallback

  scriptHistory[loadName] = new Promise((resolve) => {
    resolveCallback = resolve
  })

  return resolveCallback
}

export default LiveScriptLoader

import Swiper from 'swiper'
import { Autoplay, Navigation, Pagination, EffectFade } from 'swiper/modules'
import extender from '@vanillaUtils/Extender'
import DomHelper from '../DomHelperExtend'
import Listeners from '../ListenersExtend'

class Slideshow extends extender(DomHelper, Listeners) {
  static getDomClasses () {
    return {
      wrapper: 'js-sandboxSlideWrapper',
      slides: 'js-sandboxSlide',
      next: 'js-sandboxSlideshowNext',
      previous: 'js-sandboxSlideshowPrevious',
      pagination: 'js-sandboxSlideshowPagination'
    }
  }

  setRemainingInfo () {
    this.autoplay = {
      delay: this.domRoot.dataset.delay,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
      reverseDirection: this.domRoot.dataset.direction
    }
    this.hasMultipleSlides = this.elements.slides.length > 1

    this.elements.slides.forEach((currentSlide, index) => {
      currentSlide.dataset.index = index + 1
    })
  }

  init () {
    this.setRemainingInfo()
    const swiperConfig = {
      modules: [Autoplay, Pagination, Navigation, EffectFade],
      speed: parseInt(this.elements.wrapper?.parentElement?.dataset?.swiperSpeed || 500),
      effect: this.elements?.wrapper?.dataset?.swiperEffect,
      fadeEffect: {
        crossFade: this.elements?.wrapper?.dataset?.swiperCrossfade
      },
      slidesPerView: parseFloat(this.elements?.wrapper?.dataset?.slidesMobile || 1),
      spaceBetween: parseInt(this.elements?.wrapper?.dataset?.spaceBetweenSlides || 30),
      threshold: 15,
      autoplay: this.autoplay,
      loop: this.hasMultipleSlides,
      preloadImages: false,
      wrapperClass: this.classes.wrapper,
      slideClass: this.classes.slides,
      lazy: {
        loadOnTransitionStart: true
      },
      navigation: {
        nextEl: this.elements.next,
        prevEl: this.elements.previous
      },
      pagination: {
        el: this.elements.pagination,
        type: 'bullets',
        clickable: true
      },
      breakpoints: {}
    }

    swiperConfig.breakpoints[this.domRoot.dataset.breakpoint] = {
      slidesPerView: parseFloat(this.elements?.wrapper?.dataset?.slidesDesktop || 1)
    }

    this.swiperReference = new Swiper(this.domRoot, swiperConfig)

    if (this.autoplay.delay === '0') {
      this.domRoot.addEventListener('mouseenter', () => {
        this.swiperReference.autoplay.pause()
      })

      this.domRoot.addEventListener('mouseleave', () => {})
    }

    if (this.domRoot.querySelector('.swiper-play')) {
      this.domRoot.querySelector('.swiper-play').addEventListener('click', () => {
        this.swiperReference.autoplay.start()
      })
    }

    if (this.domRoot.querySelector('.swiper-stop')) {
      this.domRoot.querySelector('.swiper-stop').addEventListener('click', () => {
        this.swiperReference.autoplay.stop()
      })
    }

    if (!this.hasMultipleSlides) {
      this.elements.next.remove()
      this.elements.previous.remove()
      this.elements.remove()
    }
  }
}

export default Slideshow

<template>
  <TextareaField :id="input.id" v-model="input.value" />
</template>

<script>
import TextareaField from '@vanillaComponents/form_fields/Textarea.vue'

export default {
  name: 'FormFieldTextarea',

  components: { TextareaField },

  props: {
    input: {
      type: Object,
      required: true
    }
  }
}
</script>

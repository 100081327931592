import extender from '@vanillaUtils/Extender'
import Slideshow from './Slideshow'
import DomHelper from '../DomHelperExtend'
import Managers from '../ManagersExtend'

class SlideshowManager extends extender(DomHelper, Managers) {
  static getDomClasses () {
    return {
      slideshows: 'js-sandboxSlideshow'
    }
  }

  getModel (element) {
    return new Slideshow({ domRoot: element })
  }

  get listeners () {
    return this.elements.slideshows
  }
}

export default SlideshowManager

<template>
  <div class="cc-radioGroup cc-form-inputGroup">
    <i v-if="options.length === 0">
      {{ $t('no_options') }}
    </i>
    <div
      v-for="option in options"
      :key="option.id"
      class="cc-form-inputOption">
      <RadioField
        :id="option.id"
        v-model="value"
        :value="option.id"
        :label="option.name[$locale]"
        @input="handleInput" />
      <div v-if="shouldShowOtherField && value === option.id" class="cc-form-input--other">
        <cc-form-item
          :label="otherOptionTitle"
          :name="otherFieldName"
          :error-path="errorPath">
          <InputField :id="otherFieldName" v-model="otherValue" />
        </cc-form-item>
      </div>
    </div>
  </div>
</template>

<script>
import FormError from '@vanillaUtils/form/FormError'
import RadioField from '@vanillaComponents/form_fields/Radio.vue'
import InputField from '@vanillaComponents/form_fields/Input.vue'
import { isEmpty } from '@vanillaUtils/Var'

export default {
  name: 'FormFieldRadio',

  components: {
    RadioField,
    InputField
  },

  mixins: [FormError],

  emits: ['input'],

  props: {
    input: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      value: null,
      otherValue: null
    }
  },

  computed: {
    options () {
      return this.input.field_options
    },
    otherFieldName () {
      return this.input.id + '.other'
    },
    selectedOption () {
      if (this.value) {
        return this.options.find(option => option.id === this.value)
      }

      return null
    },
    shouldShowOtherField () {
      return this.value && this.selectedOption?.other
    },
    otherOptionTitle () {
      return this.selectedOption.other_label[this.$locale] || this.$t('specify')
    }
  },

  watch: {
    otherValue: 'updateValue'
  },

  methods: {
    handleInput (event) {
      this.value = event.target.value
      this.otherValue = null
      this.updateValue()
    },
    updateValue () {
      this.input.value = {
        value: this.value,
        ...(!isEmpty(this.otherValue) ? { other: this.otherValue } : {})
      }

      this.clearError(this.input.id)
      this.clearError(this.otherFieldName)
    }
  }
}
</script>

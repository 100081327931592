import trim from 'lodash/trim'

export default {
  props: {
    errorPath: {
      type: String,
      default: null
    }
  },

  computed: {
    suffixedErrorPath () {
      return this.errorPath ? (trim(this.errorPath, '.') + '.') : ''
    }
  },

  methods: {
    hasError (key, forceKey = false) {
      return this.formErrors.has(
        forceKey
          ? key
          : this.suffixedErrorPath + key
      ) !== false
    },
    errorMessage (key, forceKey = false) {
      let formErrorsGet = this.formErrors.get(forceKey ? key : this.suffixedErrorPath + key)
      if (!formErrorsGet) {
        formErrorsGet = this.formErrors.get(key + '.values')
      }
      return formErrorsGet
    },
    errorMessages (path) {
      return this.formErrors.getAll(path)
    },
    clearError (key, forceKey = false) {
      this.formErrors.forget(forceKey ? key : this.suffixedErrorPath + key)
      this.formErrors.forget(key + '.values')
    },
    clearErrors () {
      this.formErrors.forget()
    },
    addError (key, errors, forceKey = false) {
      this.formErrors.add(forceKey ? key : this.suffixedErrorPath + key, errors)
    },
    /**
     * return full path of fieldName containing formNamePrefix
     * @param {String} fieldName
     */
    getFieldName (fieldName) {
      return this.suffixedErrorPath + fieldName
    }
  },

  created () {
    if (!this.formErrors) {
      console.warn('this.formErrors is currently undefined, you might have forgotten to use "Form.js"')
    }
  }
}

<template>
  <InputField
    :id="input.id"
    v-model="input.value"
    maxlength="7" />
</template>

<script>
import InputField from '@vanillaComponents/form_fields/Input.vue'

export default {
  name: 'FormFieldPostalCode',

  components: { InputField },

  props: {
    input: {
      type: Object,
      required: true
    }
  }
}
</script>

import debounce from 'lodash/debounce'

export default function (Model) {
  class ListenersExtend extends Model {
    constructor (props) {
      super(props)

      this.domRoot = props.domRoot
    }

    init () {
      this._WARNING('init')
    }

    retryOnDomChanged () {
      if (this.observer) {
        return
      }
      this.observer = new MutationObserver((mutationList, observer) => {
        mutationList.forEach(mutation => {
          debounce(() => {
            this.runInit()
          }, 50)()
        })
      })

      this.observer.observe(this.domRoot, { attributes: false, childList: true, subtree: true })
    }

    runInit () {
      if (this.initialised) {
        return
      }
      try {
        this.findAllElements()
        this.init()
        this.initialised = true

        if (this.observer) {
          this.observer.disconnect()
          this.observer = null
        }
      } catch (error) {
        this.retryOnDomChanged()
      }
    }

    runRefresh () {
      if (this.initialised) {
        return
      }

      this.init()
    }

    _WARNING (fName = 'unknown method') {
      console.error('WARNING! "' + fName + '" is not overridden in ' + this.constructor.name)
    }
  }

  return ListenersExtend
}

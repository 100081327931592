import MobileMenuContent from './MobileMenuContent.vue'
import SlidingPanel from './SlidingPanel.vue'
import Search from './Search.vue'
import Badge from '@vanillaComponents/Badge.vue'
import Drawer from '@vanillaComponents/Drawer.vue'
import InfoIcon from '@vanillaComponents/InfoIcon.vue'
import Popper from '@vanillaComponents/Popper.vue'
import Tip from '@vanillaComponents/Tip.vue'
import '@vuepic/vue-datepicker/dist/main.css'
import Form from '@vanillaComponents/form/Form.vue'
import FormItem from '@vanillaComponents/form/FormItem.vue'
import FormError from '@vanillaComponents/form/FormError.vue'
import FormToastError from '@vanillaComponents/form/FormToastError.vue'
import VueSelect from 'vue-select'
import Html from '@vanillaComponents/Html.vue'
import Modal from '@vanillaComponents/Modal.vue'
import ModalButton from '@vanillaComponents/ModalButton.vue'
import VBModal from '@vanillaComponents/VBModal.vue'
import ShareButton from '@vanillaComponents/ShareButton.vue'
import SingleExpand from '@vanillaComponents/SingleExpand.vue'
import VueDatePicker from '@vuepic/vue-datepicker'

const components = {
  'search': Search,
  'sliding-panel': SlidingPanel,
  'mobile-menu-content': MobileMenuContent,
  'VueDatePicker': VueDatePicker,
  'cc-badge': Badge,
  'cc-drawer': Drawer,
  'cc-info-icon': InfoIcon,
  'cc-html': Html,
  'cc-popper': Popper,
  'cc-tip': Tip,
  'cc-form': Form,
  'cc-form-item': FormItem,
  'cc-form-error': FormError,
  'cc-form-toast-error': FormToastError,
  'VueSelect': VueSelect,
  'modal': Modal,
  'modal-button': ModalButton,
  'vb-modal': VBModal,
  'share-button': ShareButton,
  'single-expand': SingleExpand
}

export default {
  install: (app, options) => {
    Object.keys(components).forEach(componentKey => {
      app.component(componentKey, components[componentKey])
    })
  }
}

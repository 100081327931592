import isEmpty from 'lodash/isEmpty'

/**
 * Cheetah errors bag
 */
export default function () {
  this.errors = {}

  /**
   * Determine if the collection has any errors.
   */
  this.hasErrors = function () {
    return !isEmpty(this.errors)
  }

  /**
   * Determine if the collection has errors for a given field.
   */
  this.has = function (field) {
    if (!Object.keys(this.errors)?.length) {
      return false
    }
    if (field.includes('*')) {
      let match = false
      const regX = new RegExp('^' + field.replace('*', '[^\*]+'), 'gi')
      for (const [key, error] of Object.entries(this.errors)) {
        if (regX.test(key)) {
          match = key
          return false
        }
      }

      return match
    }

    return Object.keys(this.errors).indexOf(field) > -1 ? field : false
  }

  /**
   * Get all of the raw errors for the collection.
   */
  this.all = function () {
    return this.errors
  }

  /**
   * Return all matching errors
   */
  this.getAll = function (path) {
    return this.errors[path]
  }

  /**
   * Get the first error message for a given field.
   */
  this.get = function (field) {
    const match = this.has(field)

    if (match !== false) {
      return this.errors[match][0]
    }
  }

  /**
   * Set the raw errors for the collection.
   */
  this.set = function (errors) {
    if (typeof errors === 'object') {
      this.errors = errors
    } else {
      this.errors = { 'form': ['Something went wrong. Please try again or contact customer support.'] }
    }
  }

  /**
   * Add error to a field.
   */
  this.add = function (field, errors) {
    if (this.errors[field]?.length > 0) {
      this.errors[field].push(errors)
    } else {
      this.errors[field] = [errors]
    }
  }

  /**
   * Remove errors from the collection.
   */
  this.forget = function (field) {
    if (typeof field === 'undefined') {
      this.errors = {}
    } else {
      delete this.errors[field]
      if (field.includes('*')) {
        const regX = new RegExp(field.replace('*', '[^\*]+'), 'gi')

        this.errors.forEach((error, key) => {
          if (regX.test(key)) {
            delete this.errors[key]
          }
          regX.lastIndex = 0
        })
      }
    }
  }
}


<template>
  <div ref="popper" class="cc-popper-wrapper">
    <div
      ref="button"
      v-on="buttonEvents">
      <slot name="reference" />
    </div>
    <div
      v-show="visible && !disabled"
      ref="tooltip"
      class="cc-popper"
      :class="computedPopperClass"
      :style="{ maxWidth: maxWidth !== 'reference' ? maxWidth : null }"
      @click.stop="() => {}">
      <slot />
      <div
        v-if="!hideArrow"
        class="cc-popper-arrow"
        data-popper-arrow />
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core/lib/popper-lite'

const sameWidth = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = `${
      state.elements.reference.offsetWidth
    }px`
  }
}

export default {
  name: 'CCPopper',

  emits: ['shown', 'hidden'],

  props: {
    /**
     *
     * possible values: auto,top,right,bottom,left
     *
     * Each placement can have a variation from this list:
     * -start
     * -end
     *
     * Example: bottom-start
     */
    placement: {
      type: String,
      default: 'top'
    },
    trigger: {
      type: String,
      default: 'hover'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    popperClass: {
      type: [String, Object],
      default: null
    },
    theme: {
      type: String,
      default: null
    },
    maxWidth: {
      type: [Number, String],
      default: '200px'
    },
    offset: {
      type: Array,
      default: null
    },
    hideArrow: Boolean,
    reference: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      popper: null,
      visible: false
    }
  },

  computed: {
    computedPopperClass () {
      return [
        ...this.popperClass ? [this.popperClass] : [],
        ...this.theme ? [`cc-popper--${this.theme}`] : [],
      ]
    },
    calculatedOffset () {
      if (this.offset) {
        return this.offset
      }

      return [0, !this.hideArrow ? 8 : 0]
    },
    buttonEvents () {
      if (this.disabled) {
        return {}
      }

      if (this.trigger === 'click') {
        return {
          click: this.toggle
        }
      }

      return {
        mouseenter: this.show,
        focus: this.show,
        mouseleave: this.hide,
        blur: this.hide
      }
    }
  },

  methods: {
    toggle (event) {
      event.preventDefault()

      if (!this.visible) {
        this.show()
        this.bindCloseEvent()
      } else {
        this.hide()
      }
    },
    show () {
      this.visible = true
      this.$emit('shown')
      this.$nextTick(() => {
        this.popper.forceUpdate()
      })
    },
    hide () {
      this.visible = false
      this.$emit('hidden')
      this.unbindCloseEvent()
    },
    hideOnClickOutside (event) {
      if (!this.$refs.popper || this.$refs.popper.contains(event.target)) {
        return
      }

      this.hide()
    },
    bindCloseEvent () {
      this.$nextTick(() => {
        window.addEventListener('click', this.hideOnClickOutside)
        window.addEventListener('keyup', this.hideOnEscKeyUp)
      })
    },
    unbindCloseEvent () {
      window.removeEventListener('click', this.hideOnClickOutside)
      window.removeEventListener('keyup', this.hideOnEscKeyUp)
    },
    hideOnEscKeyUp (e) {
      if (e.keyCode === 27) {
        this.hide()
      }
    },
    forceUpdate () {
      this.popper.forceUpdate()
    }
  },

  mounted () {
    const modifiers = [
      (this.maxWidth === 'reference' ? sameWidth : {}),
      {
        name: 'offset',
        options: {
          offset: this.calculatedOffset
        }
      },
    ]

    this.popper = createPopper(this.reference ?? this.$refs.button, this.$refs.tooltip, {
      placement: this.placement,
      strategy: 'fixed',
      modifiers: modifiers
    })
  }
}
</script>

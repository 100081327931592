<template>
  <div class="cc-radioInput" :class="{ 'cc-radioInput--disabled': disabled, 'cc-radioInput--checked': checked }">
    <input
      :id="id"
      v-model="localValue"
      :value="getValueOf(value)"
      :disabled="disabled || readonly"
      :data-gtm-input="id"
      :data-gtm-input-name="slugLabel"
      class="cc-radioInput-input"
      :name="name"
      type="radio">
    <label :for="id">
      <slot name="label">
        {{ label }}
      </slot>
    </label>
  </div>
</template>

<script>
import { toRaw } from 'vue'
import UsesObjectValues from './mixins/UsesObjectValues'
import FormField from './mixins/FormField'

export default {
  name: 'CCRadioField',

  mixins: [UsesObjectValues, FormField],

  props: {
    modelValue: {
      type: [String, Object, Boolean, null],
      required: true,
      default: null
    },
    value: {
      type: [String, Number, Object, null],
      default: null
    },
    label: {
      type: [Number, String],
      default: null
    },
    name: {
      type: [String],
      default: null
    },
    id: {
      type: String,
      default: null
    },
    disabled: Boolean,
    readonly: Boolean
  },

  data () {
    return {
      radioGroup: null
    }
  },

  computed: {
    checked () {
      return toRaw(this.modelValue) === this.value
    },
    isGroup () {
      return !!this.radioGroup
    },
    localValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', this.getValueOf(value))
      }
    }
  },

  created () {
    let parent = this.$parent

    while (parent) {
      if (parent.$options.name !== 'CCRadioGroup') {
        parent = parent.$parent
      } else {
        this.radioGroup = parent
        parent = null
      }
    }
  }
}
</script>

class AnalyticsHub {
  constructor () {
    this.fetchPageInfo()
    this.customDataTransformationFunctions = []
  }

  push (eventData, withPageInfo = true) {
    if (withPageInfo) {
      eventData = this.augmentWithPageInfo(eventData)
    }
    eventData = this.runCustomDataTransformations(eventData)
    eventData = this.enforceCharacterLimit(eventData)
    window.dataLayer.push(eventData)
  }

  enforceCharacterLimit (data) {
    if (typeof data === 'object' && !Array.isArray(data) && data !== null) {
      return Object.keys(data).reduce((accumulator, dataKey) => {
        accumulator[dataKey] = this.enforceCharacterLimit(data[dataKey])
        return accumulator
      }, {})
    }

    switch (typeof data) {
      case 'string':
        return data.substring(0, 100)
      case 'object':
        if (data === null) {
          return data
        }
        return data.map((value) => {
          return this.enforceCharacterLimit(value)
        })
      default:
        return data
    }
  }

  fetchPageInfo () {
    this.pageInfo = [
      document.getElementById('gtm-page-info'),
      document.getElementById('gtm-additional-page-info'),
    ].reduce((pageInfo, node) => {
      if (!node) {
        return pageInfo
      }

      return Object.assign({}, this.getCurrentNodeData(node), pageInfo)
    }, {})
  }

  get analyticsKeyPrefix () {
    return 'gtm'
  }

  addPosition (node) {
    node.setAttribute('data-gtm-position', [...node.parentNode.children].indexOf(node))
  }

  getCurrentNodeData (node) {
    const dataKeys = Object.keys(node.dataset)
    const nodeData = {}

    for (const key of dataKeys) {
      if (key.length > this.analyticsKeyPrefix.length && key.startsWith(this.analyticsKeyPrefix)) {
        const kebabKey = key.charAt(this.analyticsKeyPrefix.length).toLowerCase() + key.slice(this.analyticsKeyPrefix.length + 1)
        nodeData[kebabKey] = node.dataset[key]
      }
    }

    return nodeData
  }

  augmentWithPageInfo (data) {
    return Object.assign({}, this.pageInfo, data)
  }

  buildEventData (currentTarget, data = {}) {
    const aggregatedData = { ...this.getCurrentNodeData(currentTarget), ...data }

    if (currentTarget.parentNode && currentTarget.parentNode !== document) {
      return this.buildEventData(currentTarget.parentNode, aggregatedData)
    }

    return aggregatedData
  }

  registerCustomDataTransformation (customTransformationFunction) {
    if (typeof customTransformationFunction !== 'function') {
      throw Error('customTransformationFunction needs to be a function')
    }

    this.customDataTransformationFunctions.push(customTransformationFunction)
  }

  runCustomDataTransformations (eventData) {
    this.customDataTransformationFunctions.forEach(customFunction => {
      eventData = customFunction(eventData)
    })

    return eventData
  }
}

const analyticsHubInstance = new AnalyticsHub()

export default analyticsHubInstance

import { isObject } from '@vanillaUtils/Var'

export default {
  props: {
    /**
     * unique identity key name for value, required when value is an object
     */
    valueKey: {
      type: String,
      default: 'value'
    }
  },

  methods: {
    getValueOf (value) {
      if (isObject(value)) {
        return value[this.valueKey]
      }

      return value
    }
  }
}


function isObject (value) {
  return Object.prototype.toString.call(value).toLowerCase() === '[object object]'
}

function isArray (value) {
  return Object.prototype.toString.call(value).toLowerCase() === '[object array]'
}

function isEmpty (value) {
  if (isArray(value)) {
    return value.length === 0
  }

  return value === null || value === undefined || value === ''
}

export {
  isObject,
  isArray,
  isEmpty
}

import extender from '@vanillaUtils/Extender'
import DomHelper from '../DomHelperExtend'
import Managers from '../ManagersExtend'
import Video from './Video'

class VideosManager extends extender(DomHelper, Managers) {
  static getDomClasses () {
    return {
      videos: 'js-startableVideo'
    }
  }

  getModel (element) {
    return new Video({ domRoot: element })
  }

  get listeners () {
    return this.elements.videos
  }
}

export default VideosManager

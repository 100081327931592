<template>
  <input
    :id="id"
    :value="modelValue"
    :name="id"
    :type="inputType"
    :disabled="disabled"
    :placeholder="placeholder"
    :readonly="readonly"
    class="cc-textInput"
    @input="onChange">
</template>

<script>
import FormField from './mixins/FormField'

export default {
  name: 'CCDateField',

  mixins: [FormField],

  props: {
    modelValue: {
      type: [String, null],
      required: true,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    disabled: Boolean,
    readonly: Boolean
  },

  computed: {
    inputType () {
      if (this.type === 'datetime') {
        return 'datetime-local'
      }

      return 'date'
    }
  },

  methods: {
    onChange (event) {
      this.$emit('update:modelValue', event.target.value)
    }
  }
}
</script>

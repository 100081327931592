import Tabs from './Tabs'
import extender from '@vanillaUtils/Extender'
import DomHelper from '@vanilla/dom_modules/DomHelperExtend'
import Managers from '@vanilla/dom_modules/ManagersExtend'

class TabsManager extends extender(DomHelper, Managers) {
  static getDomClasses () {
    return {
      tabs: 'js-tabs'
    }
  }

  getModel (element) {
    return new Tabs({ domRoot: element })
  }

  get listeners () {
    return this.elements.tabs
  }
}

export default TabsManager

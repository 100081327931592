
import deburr from 'lodash/deburr'

let lastTime = 0
let timeout

export default class Functions {
  static throttle (func, timeFrame) {
    return function (...args) {
      const now = new Date()
      if (now - lastTime >= timeFrame) {
        func(...args)
        lastTime = now
      }
    }
  }

  static debounce (func, wait, immediate) {
    return function () {
      const context = this
      const args = arguments

      clearTimeout(timeout)

      timeout = setTimeout(function () {
        timeout = null
        if (!immediate) func.apply(context, args)
      }, wait)
      if (immediate && !timeout) func.apply(context, args)
    }
  }

  static slugifyStr (str) {
    return deburr(str).replace(/\s+/g, '-').toLowerCase()
  }

  static TranslatedFieldValue (defaultValue) {
    const translated = {}

    window.app.contentLanguages.forEach(langKey => {
      translated[langKey] = defaultValue || ''
    })

    return translated
  }
}

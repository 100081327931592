<template>
  <div v-html="html" />
</template>

<script>
export default {
  name: 'CCHtml',

  props: {
    html: {
      type: String,
      required: true
    }
  }
}
</script>

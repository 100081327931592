const param = function (e) {
  const n = []
  const o = function (e, o) {
    o = (o = typeof o === 'function' ? o() : o) == null ? '' : o, n[n.length] = encodeURIComponent(e) + '=' + encodeURIComponent(o)
  }
  const t = function (e, r) {
    let f, i, l

    if (e) {
      if (Array.isArray(r)) {
        for (f = 0, i = r.length; f < i; f++) t(e + '[' + (typeof r[f] === 'object' && r[f] ? f : '') + ']', r[f])
      } else if (Object.prototype.toString.call(r) === '[object Object]') {
        for (l in r) t(e + '[' + l + ']', r[l])
      } else {
        o(e, r)
      }
    } else if (Array.isArray(r)) {
      for (f = 0, i = r.length; f < i; f++) o(r[f].name, r[f].value)
    } else {
      for (l in r) t(l, r[l])
    }
    return n
  }

  return t('', e).join('&')
}

export default param
